import { FC, useMemo } from 'react';
import { Badge } from '../';

export interface AvailabilityStateTagProps {
	order: number;
	stock: number;
	qty: number;
	className?: string;
}

export const AvailabilityStateTag: FC<AvailabilityStateTagProps> = ({
	order,
	stock,
	qty,
	className,
}) => {
	const stateMap = {
		green: 'green',
		red: 'red',
		orange: 'orange',
	};

	const state = useMemo<string>(() => {
		let color = '';
		if (stock >= qty) color = stateMap['green'];
		if (stock !== null && stock > 0 && stock < qty) color = stateMap['orange'];
		if (!stock || stock == 0 || stock == null) color = stateMap['red'];
		return color;
	}, [order, stock, qty]);

	return <Badge color={state} text='' className={className} />;
};
