import { Breadcrumb as AntBreadcrumb } from 'antd';
import { BreadcrumbProps } from 'antd/lib/breadcrumb';

const { Item: AntItem } = AntBreadcrumb;

export const Breadcrumb = (props: BreadcrumbProps) => {
	return <AntBreadcrumb {...props}>{props.children}</AntBreadcrumb>;
};

const Item = (props: BreadcrumbProps) => {
	return <AntItem {...props}>{props.children}</AntItem>;
};

Breadcrumb.Item = Item;
