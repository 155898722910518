import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import icon from '@/assets/icons';
import { t } from '@/core/hooks/useTranslation';

export const ContactButton = () => {
	const lang = useSelector<any>((state) => state?.user?.lang) as any;
	const navigate = useNavigate();

	const ContactIconBox = styled.div`
		.expandable {
			height: 40px;
			transition: width 0.2s ease, transform 0.2s ease;
			position: relative;
			display: flex;
			align-items: center;
			transform-origin: right;
		}

		.expandable:hover {
			width: ${lang == 'en' ? '123px' : '139px'};
			transform: ${lang == 'en' ? 'translateX(-67%)' : 'translateX(-70%)'};
			.text_contact {
				display: block;
			}
			.expandable img {
				margin-top: 5px;
			}
		}

		.text_contact {
			padding: 10px 0;
			display: none;
		}
	`;

	const clickContact = () => {
		navigate('/contact-us');
	};

	return (
		<ContactIconBox
			lang={lang}
			className='fixed right-[calc(120px/2)] bottom-[150px] z-30 cursor-pointer w-[40px]'
			onClick={clickContact}
		>
			<div className='bg-white rounded-full flex justify-center custom-top-shadow expandable'>
				<div className='text_contact mr-2'>{t('HomeJp.CONTACT_US')}</div>
				<img src={icon?.contact} className='w-[30px] h-[30px]' />
			</div>
		</ContactIconBox>
	);
};
