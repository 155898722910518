import { FC } from 'react';

export interface AvailabilityProps {
	stock: any;
	qty: number;
}

export const Availability: FC<AvailabilityProps> = ({ qty, stock }) => {
	if (stock?.inStockQuantity >= qty) {
		return <div className='mb-1'>In Stock</div>;
	} else if (stock?.inStockQuantity > 0 && stock?.inStockQuantity < qty) {
		return (
			<>
				<div className='mb-1'>
					In Stock
					<span>（{stock?.inStockQuantity}）</span>
				</div>
				<div className='mb-1'>On Order （{stock?.backOrdQuantity}）</div>
			</>
		);
	} else if (
		!stock?.inStockQuantity ||
		stock?.inStockQuantity == null ||
		stock?.inStockQuantity == 0
	) {
		return <div className='mb-1'>On Order</div>;
	} else {
		return null;
	}
};
