import React, { lazy, Suspense } from 'react';
import { Spin } from '@/components';

const LoadingFallback = () => (
	<div className='p-4 flex justify-center'>
		<Spin tip='Loading...' />
	</div>
);

export const LazyLoad = ({ children }: { children: JSX.Element }) => {
	return <Suspense fallback={<LoadingFallback />}>{children}</Suspense>;
};

const VerticalMenu = lazy(() => import('./EN/VerticalMenu'));
const VerticalMenuLayout = ({ children }: { children: JSX.Element }) => {
	return (
		<div className='flex'>
			<LazyLoad>
				<VerticalMenu />
			</LazyLoad>
			{children}
		</div>
	);
};

// LazyLoad Sample

const Login = lazy(() => import('./EN/Login'));
const LazyLoadLogin = () => {
	return (
		<LazyLoad>
			<Login />
		</LazyLoad>
	);
};

const SuccessLogin = lazy(() => import('./EN/Login/SuccessLogin'));
const LazyLoadSuccessLogin = () => {
	return (
		<LazyLoad>
			<SuccessLogin />
		</LazyLoad>
	);
};

const LoginJP = lazy(() => import('./JP/Login'));
const LazyLoadLoginJP = () => {
	return (
		<LazyLoad>
			<LoginJP />
		</LazyLoad>
	);
};

const Home = lazy(() => import('./EN/Home'));
const LazyLoadHome = () => {
	return (
		<LazyLoad>
			<Home />
		</LazyLoad>
	);
};

const HomeJP = lazy(() => import('./JP/Home'));
const LazyLoadHomeJP = () => {
	return (
		<LazyLoad>
			<HomeJP />
		</LazyLoad>
	);
};

const ShoppingCart = lazy(() => import('./EN/ShoppingCart'));
const LazyLoadShoppingCart = () => {
	return (
		<LazyLoad>
			<ShoppingCart />
		</LazyLoad>
	);
};

const ProductSearch = lazy(() => import('./EN/ProductSearch'));
const LazyLoadProductSearch = () => {
	return (
		<LazyLoad>
			<ProductSearch />
		</LazyLoad>
	);
};

const Checkout = lazy(() => import('./EN/Checkout'));
const LazyLoadCheckout = () => {
	return (
		<LazyLoad>
			<Checkout />
		</LazyLoad>
	);
};

const Successfully = lazy(() => import('./EN/Successfully'));
const LazyLoadSuccessfully = () => {
	return (
		<LazyLoad>
			<Successfully />
		</LazyLoad>
	);
};

const OrderHistory = lazy(() => import('./EN/OrderHistory'));
const LazyLoadOrderHistory = () => {
	return (
		<LazyLoad>
			<OrderHistory />
		</LazyLoad>
	);
};

const Exceptional = lazy(() => import('./EN/Exceptional'));
const LazyLoadExceptional = () => {
	return (
		<LazyLoad>
			<Exceptional />
		</LazyLoad>
	);
};

const OrderDetail = lazy(() => import('./EN/OrderDetail'));
const LazyLoadOrderDetail = () => {
	return (
		<LazyLoad>
			<OrderDetail />
		</LazyLoad>
	);
};

const ProfileAccount = lazy(() => import('./EN/Profile'));
const LazyLoadProfileAccount = () => {
	return (
		<LazyLoad>
			<ProfileAccount />
		</LazyLoad>
	);
};

const Favorite = lazy(() => import('./EN/Favorite'));
const LazyLoadFavorite = () => {
	return (
		<LazyLoad>
			<Favorite />
		</LazyLoad>
	);
};

const Notification = lazy(() => import('./JP/Notification'));
const LazyLoadNotification = () => {
	return (
		<LazyLoad>
			<Notification />
		</LazyLoad>
	);
};

const LoginReview = lazy(() => import('./JP/Login/LoginReview'));
const LazyLoadLoginReview = () => {
	return (
		<LazyLoad>
			<LoginReview />
		</LazyLoad>
	);
};

const LoginInactive = lazy(() => import('./JP/Login/LoginInactive'));
const LazyLoadLoginInactive = () => {
	return (
		<LazyLoad>
			<LoginInactive />
		</LazyLoad>
	);
};

/**
 * ProductsJp
 * @param {} path
 * @returns {JSX.Element} LazyLoadProductsJp
 */
const ProductsJp = lazy(() => import('./JP/Products'));
const LazyLoadProductsJp = () => {
	return (
		<LazyLoad>
			<ProductsJp></ProductsJp>
		</LazyLoad>
	);
};

/**
 * MyFavoriteJp
 * @param {} path
 * @returns {JSX.Element} LazyLoadMyFavoriteJp
 */
const MyFavoriteJp = lazy(() => import('./JP/MyFavorite'));
const LazyLoadMyFavoriteJp = () => {
	return (
		<LazyLoad>
			<MyFavoriteJp></MyFavoriteJp>
		</LazyLoad>
	);
};

/**
 * ServiceJp
 * @param {} path
 * @returns {JSX.Element} LazyLoadServiceJp
 */
const ServiceJp = lazy(() => import('./JP/Service'));
const LazyLoadServiceJp = () => {
	return (
		<LazyLoad>
			<ServiceJp></ServiceJp>
		</LazyLoad>
	);
};

/**
 * HelpJp
 * @param {} path
 * @returns {JSX.Element} LazyLoadHelpJp
 */
const HelpJp = lazy(() => import('./JP/Help'));
const LazyLoadHelpJp = () => {
	return (
		<LazyLoad>
			<HelpJp></HelpJp>
		</LazyLoad>
	);
};

/**
 * MyProfileJp
 * @param {} path
 * @returns {JSX.Element} LazyLoadMyProfileJp
 */
const MyProfileJp = lazy(() => import('./JP/MyProfile'));
const LazyLoadMyProfileJp = () => {
	return (
		<LazyLoad>
			<MyProfileJp></MyProfileJp>
		</LazyLoad>
	);
};

const SearchResult = lazy(() => import('./JP/SearchResult'));
const LazyLoadSearchResult = () => {
	return (
		<LazyLoad>
			<SearchResult />
		</LazyLoad>
	);
};

const AdminLayout = lazy(() => import('./JP/Admin'));
const LazyAdminLayout = () => {
	return (
		<LazyLoad>
			<AdminLayout />
		</LazyLoad>
	);
};

const AdminDistributorUsers = lazy(() => import('./JP/Admin/DistributorUsers'));
const LazyAdminDistributorUsers = () => {
	return (
		<LazyLoad>
			<AdminDistributorUsers />
		</LazyLoad>
	);
};

const AdminInternalUsers = lazy(() => import('./JP/Admin/InternalUsers'));
const LazyAdminInternalUsers = () => {
	return (
		<LazyLoad>
			<AdminInternalUsers />
		</LazyLoad>
	);
};

const ContactUsJp = lazy(() => import('./JP/ContactUs'));
const LazyContactUsJp = () => {
	return (
		<LazyLoad>
			<ContactUsJp />
		</LazyLoad>
	);
};

const AddtionalInfoJp = lazy(() => import('./JP/Addtional'));
const LazyLoadAddtionalJp = () => {
	return (
		<LazyLoad>
			<AddtionalInfoJp />
		</LazyLoad>
	);
};

const Error404 = lazy(() => import('./JP/ErrorPage/404'));
const LazyLoadError404 = () => {
	return (
		<LazyLoad>
			<Error404 />
		</LazyLoad>
	);
};

const FAQ = lazy(() => import('./JP/FAQ/index'));
const LazyLoadFAQ = () => {
	return (
		<LazyLoad>
			<FAQ />
		</LazyLoad>
	);
};

const ShowFile = lazy(() => import('./JP/ShowFile'));
const LazyLoadShowFile = () => {
	return (
		<LazyLoad>
			<ShowFile />
		</LazyLoad>
	);
};

const FormInquiryFTIR = lazy(() => import('./JP/FormInquiryContact/FTIR'));
const LazyFormInquiryFTIR = () => {
	return (
		<LazyLoad>
			<FormInquiryFTIR />
		</LazyLoad>
	);
};

const FormInquiryUVVis = lazy(() => import('./JP/FormInquiryContact/UVVis'));
const LazyFormInquiryUVVis = () => {
	return (
		<LazyLoad>
			<FormInquiryUVVis />
		</LazyLoad>
	);
};

const MobileLogin = lazy(() => import('./JP/Login/Mobile/index'));
const LazyMobileLogin = () => {
	return (
		<LazyLoad>
			<MobileLogin />
		</LazyLoad>
	);
};

const MobileLoginInactive = lazy(
	() => import('./JP/Login/Mobile/LoginInactive')
);
const LazyMobileLoginInactive = () => {
	return (
		<LazyLoad>
			<MobileLoginInactive />
		</LazyLoad>
	);
};

const MobileLoginReview = lazy(() => import('./JP/Login/Mobile/LoginReview'));
const LazyMobileLoginReview = () => {
	return (
		<LazyLoad>
			<MobileLoginReview />
		</LazyLoad>
	);
};

const MobileAddtionalInfoJp = lazy(() => import('./JP/Addtional/Mobile'));
const LazyMobileAddtionalInfoJp = () => {
	return (
		<LazyLoad>
			<MobileAddtionalInfoJp />
		</LazyLoad>
	);
};

const MobileAddtionalHavebranch = lazy(
	() => import('./JP/Addtional/Mobile/Havebranch')
);
const LazyMobileAddtionalHavebranch = () => {
	return (
		<LazyLoad>
			<MobileAddtionalHavebranch />
		</LazyLoad>
	);
};

const MobileAddtionalNotbranch = lazy(
	() => import('./JP/Addtional/Mobile/Notbranch')
);
const LazyMobileAddtionalNotbranch = () => {
	return (
		<LazyLoad>
			<MobileAddtionalNotbranch />
		</LazyLoad>
	);
};

export {
	LazyLoadHome,
	LazyLoadShoppingCart,
	LazyLoadProductSearch,
	LazyLoadCheckout,
	LazyLoadSuccessfully,
	LazyLoadOrderHistory,
	LazyLoadExceptional,
	LazyLoadOrderDetail,
	LazyLoadProfileAccount,
	VerticalMenuLayout,
	LazyLoadSuccessLogin,
	LazyLoadLogin,
	LazyLoadFavorite,
	LazyLoadLoginJP,
	LazyLoadHomeJP,
	LazyLoadNotification,
	LazyLoadLoginReview,
	LazyLoadLoginInactive,
	LazyLoadProductsJp,
	LazyLoadMyFavoriteJp,
	LazyLoadServiceJp,
	LazyLoadHelpJp,
	LazyLoadSearchResult,
	LazyLoadMyProfileJp,
	LazyAdminLayout,
	LazyAdminDistributorUsers,
	LazyAdminInternalUsers,
	LazyContactUsJp,
	LazyLoadAddtionalJp,
	LazyLoadError404,
	LazyLoadFAQ,
	LazyLoadShowFile,
	LazyFormInquiryFTIR,
	LazyFormInquiryUVVis,
	LazyMobileLogin,
	LazyMobileLoginInactive,
	LazyMobileLoginReview,
	LazyMobileAddtionalInfoJp,
	LazyMobileAddtionalHavebranch,
	LazyMobileAddtionalNotbranch,
};
