/*
 * @Author: yang.jinpeng
 * @Date: 2023-11-18 14:13:15
 * @Last Modified time: 2023-11-18 14:13:15
 */

import { forwardRef, ReactNode } from 'react';
import {
	Form as AntForm,
	FormProps as AntFormProps,
	FormItemProps as AntFormItemProps,
	FormInstance as AntFormInstance,
} from 'antd';

interface FormItemProps extends Omit<AntFormItemProps, 'name' | 'label'> {
	name: string; // 表单字段名称
	label: string | ReactNode; // 表单 label
}

type FormType = {
	props: Partial<FormItemProps>;
	Component: any;
	componentProps?: any;
	componentChildren?:
		| ReactNode
		| ((watcher?: typeof AntForm.useWatch) => ReactNode);
	watcher?: typeof AntForm.useWatch;
	filter?: (watcher?: typeof AntForm.useWatch) => boolean;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface FormProps<Values = any> extends Omit<AntFormProps<Values>, 'form'> {
	form?: AntFormInstance<Values>;
	formMap?: Map<string, Partial<FormType>>;
	formOrder?: string[];
	disabledForms?: string[];
}

function getItem(
	form: Partial<FormType> | undefined,
	key: string,
	disabledForms: string[] = []
): ReactNode | null {
	if (!form) {
		return null;
	}
	return (
		<AntForm.Item key={key} {...form.props}>
			{form.Component && (
				<form.Component
					key={form.props?.name}
					{...form.componentProps}
					disabled={disabledForms.includes(form.props?.name || '')}
				>
					{typeof form.componentChildren === 'function'
						? form.componentChildren(form.watcher)
						: form.componentChildren}
				</form.Component>
			)}
		</AntForm.Item>
	);
}

const BaseForm: any = forwardRef<AntFormInstance>((props: any, ref) => {
	return (
		<AntForm ref={ref} {...props}>
			<>{props.children}</>
		</AntForm>
	);
});

const CustomForm: any = forwardRef<AntFormInstance>((props: any, ref) => {
	const {
		form,
		formOrder = [],
		formMap = new Map(),
		disabledForms = [],
		...other
	} = props;
	return (
		<AntForm ref={ref} {...other} form={form}>
			<>
				{formOrder.map((name: string) => {
					return getItem(formMap.get(name), name, disabledForms);
				})}
				{props.children}
			</>
		</AntForm>
	);
});

BaseForm.useForm = AntForm.useForm;
BaseForm.useWatch = AntForm.useWatch;
BaseForm.useFormInstance = AntForm.useFormInstance;
BaseForm.Item = AntForm.Item;
BaseForm.List = AntForm.List;
BaseForm.ErrorList = AntForm.ErrorList;
BaseForm.Provider = AntForm.Provider;

CustomForm.useForm = AntForm.useForm;
CustomForm.useWatch = AntForm.useWatch;
CustomForm.useFormInstance = AntForm.useFormInstance;
CustomForm.Item = AntForm.Item;
CustomForm.List = AntForm.List;
CustomForm.ErrorList = AntForm.ErrorList;
CustomForm.Provider = AntForm.Provider;

const Form: any = BaseForm;
const FormNext: any = CustomForm;
export { Form, FormNext, type FormType, type AntFormInstance as FormInstance };
