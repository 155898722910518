import singaporeIcon from './singapore-icon.svg';
import quickAccessArrow from './quick-access-arrow.svg';
import quickOrderIcon from './home2.svg';
import inquiryOrderIcon from './home3.svg';
import contactUsIcon from './home1.svg';
import ft_ir from './ft_ir.svg';
import uv_vis from './uv-vis.svg';
import jpLogo from './/jp.svg';
import userLogo from './user.svg';
import logoutLogo from './logout.svg';
import peopleLogo from './people.svg';
import homeMachine from './home_machine.svg';
import excelIocn from './excel_iocn.svg';
import pdfIocn from './pdf_iocn.svg';
import machine1 from './machine1.svg';
import machine2 from './machine2.svg';
import machineJP from './machineJP.svg';
import newLogo from './new_logo.svg';
import newLogo2 from './new_logo2.svg';
import down from './down.svg';
import up from './up.svg';
import logo from './logo.svg';
import red_circle from './red_circle.svg';
import gray_circle from './gray_circle.svg';
import newLogo3 from './new_logo3.svg';
import down_filled from './down_filled.svg';
import file_unknown from './file-unknown.svg';
import file_icon_link from './file-icon-link.svg';
import file_icon_earth from './file-icon-earth.svg';
import file_icon_csv from './file-icon-csv.svg';
import file_icon_bmp from './file-icon-bmp.svg';
import file_icon_jpg from './file-icon-jpg.svg';
import file_icon_word from './file-icon-word.svg';
import noSearch from './noSearch.svg';
import file_icon_video from './file-icon-video.svg';
import arrow_up_icon from './arrow-up-icon.svg';
import arrow_up_icon_black from './arrow-up-icon-black.svg';
import upload_cloud_icon from './upload-cloud-icon.svg';
import file_icon_upload from './file-icon-upload.svg';
import calendar_icon from './calendar-icon.svg';
import product_admin from './product_admin.svg';
import notification_admin from './notification_admin.svg';
import service_admin from './service_admin.svg';
import contact_us_phone from './contact-us-phone.svg';
import contact_us_email from './contact-us-email.svg';
import contact_us_mark from './contact-us-mark.svg';
import contact_us_location from './contact-us-location.svg';
import setting from './setting.svg';
import profile_company_icon from './profile-company-icon.svg';
import profile_user_icon from './profile-user-icon.svg';
import illustration from './Illustration.svg';
import top from './TOP.svg';
import top1 from './TOP1.svg';
import top2 from './TOP2.svg';
import top_top from './TOP_top.svg';
import file_icon_gif from './file-icon-gif.svg';
import contact from './contact.svg';
import formFloatButton from './formFloatButton.svg';
import japanIocon from './Japan.svg';
import englishIocon from './English.svg';

export default {
	singaporeIcon,
	quickAccessArrow,
	quickOrderIcon,
	inquiryOrderIcon,
	contactUsIcon,
	ft_ir,
	uv_vis,
	jpLogo,
	userLogo,
	logoutLogo,
	peopleLogo,
	homeMachine,
	excelIocn,
	pdfIocn,
	machine1,
	machine2,
	newLogo,
	newLogo2,
	down,
	up,
	logo,
	red_circle,
	gray_circle,
	newLogo3,
	down_filled,
	file_unknown,
	file_icon_link,
	file_icon_earth,
	file_icon_csv,
	file_icon_bmp,
	file_icon_jpg,
	file_icon_word,
	noSearch,
	file_icon_video,
	arrow_up_icon,
	arrow_up_icon_black,
	upload_cloud_icon,
	file_icon_upload,
	calendar_icon,
	product_admin,
	notification_admin,
	service_admin,
	contact_us_phone,
	contact_us_email,
	contact_us_mark,
	contact_us_location,
	setting,
	profile_company_icon,
	profile_user_icon,
	illustration,
	top1,
	top2,
	file_icon_gif,
	top,
	top_top,
	contact,
	machineJP,
	formFloatButton,
	japanIocon,
	englishIocon,
};
