import { Typography as AntTypography } from 'antd';
import { TypographyProps } from 'antd/lib/typography/Typography';

const { Paragraph: AntParagraph, Text: AntText } = AntTypography;

export const Typography = (props: TypographyProps<any>) => {
	return <AntTypography {...props}></AntTypography>;
};

const Paragraph = (props: any) => {
	return <AntParagraph {...props}>{props.children}</AntParagraph>;
};

const Text = (props: any) => {
	return <AntText {...props}>{props.children}</AntText>;
};

Typography.Paragraph = Paragraph;
Typography.Text = Text;
