import { createSlice } from '@reduxjs/toolkit';

export interface CheckOutInfoType {
	sku?: string;
	skuName?: string;
}

export interface ShoppingCartState {
	checkoutInfo: any;
	quotData: any;
	addressData: any;
}

const initialState = {
	checkoutInfo: {},
	quotData: {},
	addressData: {},
} as ShoppingCartState;

const shoppingCart = createSlice({
	name: 'shoppingCart',
	initialState,
	reducers: {
		setCheckoutInfo(state, action) {
			state.checkoutInfo = action.payload;
		},
		setQuotData(state, action) {
			state.quotData = action.payload;
		},
		setAddressData(state, action) {
			state.addressData = action.payload;
		},
	},
});

export const { setCheckoutInfo, setQuotData, setAddressData } =
	shoppingCart.actions;
export default shoppingCart.reducer;
