import React from 'react';
import styled from '@emotion/styled';
import { Outlet } from 'react-router-dom';
import { Header, Footer } from '@/containers/EN';

const AppContainer = styled('div')`
	min-height: 360px;
`;

const AppLayout = () => {
	return (
		<AppContainer className='w-full'>
			<Header />
			<Outlet />
			<Footer />
		</AppContainer>
	);
};

export default AppLayout;
