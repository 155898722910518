import { http, ResponseBaseType } from '@/core/services/http.service';
import toString from 'lodash/toString';

export interface newsUnreadType {
	contentType: number;
	leafCategoryIds: Array<number>;
	productLineId: number;
}

export interface setReadType {
	contentType: number;
	contentId: number;
	productLineId: number;
	categoryId: number;
}

export interface setFavouriteType {
	contentType: number;
	contentId: number;
	productLineId: number;
	categoryId: number | string | null;
}

export interface downloadFileType {
	id: number;
}
class UserJp {
	categoryList = async (data: number) => {
		const response = await http.post<number, ResponseBaseType<any>>(
			'/category/list',
			data
		);
		return response;
	};

	getNewsUnread = async (data: newsUnreadType) => {
		const response = await http.post<
			newsUnreadType,
			ResponseBaseType<newsUnreadType>
		>('/category/checkUnreadMsg', data);
		return response;
	};

	setRead = async (data: setReadType) => {
		const response = await http.post<setReadType, ResponseBaseType<any>>(
			'/content/read',
			data
		);
		return response;
	};

	setFavourite = async (data: setFavouriteType) => {
		const response = await http.post<setFavouriteType, ResponseBaseType<any>>(
			'/content/favourite',
			data
		);
		return response;
	};

	getDownloadFile = async (data: downloadFileType) => {
		const response = await http.fileDownload<
			downloadFileType,
			ResponseBaseType<any>
		>('/documents/download', data);
		return response;
	};

	private productLine = 'productLine';

	getProductLine = () => {
		return localStorage.getItem(this.productLine) || '';
	};

	setProductLine = (item: any) => {
		return localStorage.setItem(this.productLine, toString(item));
	};

	removeProductLine = () => {
		return localStorage.removeItem(this.productLine);
	};
}

export const getuser = new UserJp();
