import { Dropdown as AntDropdown } from 'antd';
import { DropdownProps, DropdownButtonProps } from 'antd/lib/dropdown';

const { Button: AntButton } = AntDropdown;

export const Dropdown = (props: DropdownProps) => {
	return <AntDropdown {...props}>{props.children}</AntDropdown>;
};

const Button = (props: DropdownButtonProps) => {
	return <AntButton {...props}>{props.children}</AntButton>;
};

Dropdown.Button = Button;
