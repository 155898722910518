import { FC, useMemo } from 'react';
import { Dropdown, Menu } from '@/components';
import type { MenuProps } from 'antd';
import { Link } from 'react-router-dom';
import { DownOutlined, EllipsisOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { t } from '@/core/hooks/useTranslation';
import head from 'lodash/head';
import upperCase from 'lodash/upperCase';
import { useSelector } from 'react-redux';
import { getUserAgent } from '@/core/utils';

export interface NavProps {
	accountItems?: MenuProps['items'];
	accountClick?: MenuProps['onClick'];
	navItems?: MenuProps['items'];
	navItemOnClick?: MenuProps['onClick'];
	selectedKeys?: Array<string>;
	clickLink: MenuProps['onClick'];
	quickLink?: MenuProps['items'];
}

const StyleMenu = styled(Menu)`
	border: none;
	max-width: 54rem;
	.ant-menu-item-selected {
		color: #000 !important;
		font-weight: 600 !important;
	}
	.ant-menu-overflow-item,
	ant-menu-item,
	ant-menu-item-only-child {
		margin: 0 0.6rem !important;
		padding: 0 !important;
	}

	.ant-menu-item-selected .ant-menu-title-content {
		display: block !important;
		box-shadow: 0px 2px 0px 0px #e71316;
	}

	.ant-menu-item-only-child .ant-menu-title-content:hover {
		display: block !important;
		box-shadow: 0px 2px 0px 0px #e71316;
	}
	.ant-menu-item-only-child::after {
		border-bottom: 0 !important;
	}

	.ant-menu-submenu-selected::after {
		border-bottom: 0 !important;
	}
`;

const defaultItems: MenuProps['items'] = [
	{
		label: 'default',
		key: 'default',
	},
];

const Nav: FC<NavProps> = ({
	accountItems,
	accountClick,
	navItems,
	navItemOnClick,
	selectedKeys,
	clickLink,
	quickLink,
}) => {
	const userName: any = useSelector<any>((item: any) => item?.user?.userName);
	const defaultSelectedKeys = useMemo<MenuProps['defaultSelectedKeys']>(() => {
		if (!navItems || !navItems?.length) return undefined;

		if (typeof navItems[0]?.key !== 'string') return undefined;

		return [navItems[0]?.key];
	}, [navItems]);

	return (
		<div className='flex pt-6 pb-4 justify-between'>
			<div className='flex max-w-[70%]'>
				<div className='flex'>
					<Link to='/home'>
						<div className='bg-logo w-44 h-8 bg-contain bg-no-repeat border-0 border-r-2 border-gray-200 border-solid pr-6' />
					</Link>
					<div className='h-8 text-2xl text-neutral-800 pl-4 whitespace-nowrap'>
						{t('HomeJp.PartnerWebsite')}
					</div>
				</div>
				<div
					className={`-mt-[0.4rem] ${
						getUserAgent() == 'Edge' ? '' : 'ml-10'
					} w-[57%]`}
				>
					<StyleMenu
						mode='horizontal'
						overflowedIndicator={<EllipsisOutlined />}
						items={navItems}
						onClick={navItemOnClick}
						defaultSelectedKeys={defaultSelectedKeys}
						selectedKeys={selectedKeys}
					/>
				</div>
			</div>
			<div className='flex h-8 py-1 items-center justify-between'>
				<div className='ml-2 cursor-pointer'>
					<Dropdown
						menu={{
							items: quickLink,
							onClick: clickLink,
						}}
					>
						<div className='text-sm flex whitespace-nowrap'>
							{t('HomeJp.QuickLink')}
							<DownOutlined className='ml-1' />
						</div>
					</Dropdown>
				</div>
				<div className='ml-[1.5rem] mr-2 cursor-pointer whitespace-nowrap flex items-center'>
					<div className='w-6 h-6 bg-blue-400 flex items-center justify-center text-white mr-2 rounded-full'>
						{upperCase(head(userName))}
					</div>
					<Dropdown
						menu={{
							items: accountItems || defaultItems,
							onClick: accountClick,
						}}
					>
						<span>
							{t('HomeJp.MyAccount')}
							<DownOutlined className='ml-2' />
						</span>
					</Dropdown>
				</div>
			</div>
		</div>
	);
};

export default Nav;
