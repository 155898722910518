import includes from 'lodash/includes';
import { user } from './user.service';
const regionData = ['JP', 'en', 'cn'];
const base = '/api';

//监听localStorage中的region项的变化

// const orignalSetItem = localStorage.setItem;
// localStorage.setItem = function (key, newValue) {
// 	const setItemEvent: any = new Event('setItemEvent');
// 	setItemEvent['key'] = key;
// 	setItemEvent['newValue'] = newValue;
// 	setItemEvent['oldValue'] = localStorage.getItem(key);
// 	window.dispatchEvent(setItemEvent);
// 	orignalSetItem.apply(this, [key, newValue]);
// };


// window.addEventListener('setItemEvent', (e: any) => {
// 	if (e?.key == 'region') {
// 		if (e?.newValue !== e?.oldValue) {
// 			region = e?.newValue;
// 			if (window.name != 'reload') {
// 				// location.reload();
// 				window.name = 'reload';
// 			} else {
// 				window.name = '';
// 			}
// 		} else {
// 			window.name = '';
// 		}
// 	}
// });

const baseUrl = () => {
	window.name = '';
	if (includes(regionData, user.getRegion())) {
		switch (user.getRegion()) {
			case 'EN':
				return `/${user.getRegion()}`;
			case 'CN':
				return '';
			case 'JP':
				return '';
			default:
				return '';
		}
	} else {
		return '';
	}
};
export default `${base}${baseUrl()}`;
