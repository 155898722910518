import { Badge as AntBadge } from 'antd';
import { BadgeProps } from 'antd/lib/badge';
import { RibbonProps } from 'antd/lib/badge/Ribbon';

const { Ribbon: AntRibbon } = AntBadge;

export const Badge = (props: BadgeProps) => {
	return <AntBadge {...props}>{props.children}</AntBadge>;
};

const Ribbon = (props: RibbonProps) => {
	return <AntRibbon {...props}>{props.children}</AntRibbon>;
};

Badge.Ribbon = Ribbon;
