import { http, ResponseBaseType } from '@/core/services/http.service';

export interface SetFavoriteType {
	productLine: string;
	sku: string;
	uom: string;
	skuName: string;
	currency: string;
	contractTaxesPrice: number;
}

export interface RemoveFavoriteType {
	productLine: string;
	sku: string;
	uom: string;
	skuName: string;
	currency: string;
	contractTaxesPrice: number;
}

export interface DeleteFavoriteType {
	idList: Array<string | number>;
}

export interface SearchFavoriteType {
	key: string;
	pageNo: number;
	pageSize: number;
	count: number;
}

export interface FavoriteListJpType {
	productLineId: string | number;
	categoryIds: Array<string>;
	keywords?: string;
	pageNo: number;
	pageSize: number;
}

export enum ContentTypeEnum {
	// 0：notification  1：product  2：notification 3: help
	'notification' = '0',
	'product' = '1',
	'service' = '2',
	'help' = '3',
}

export interface FavoriteReadJpType {
	contentType: ContentTypeEnum;
	contentId: number | string;
	productLineId?: string | number;
	categoryId?: Array<string>;
}

class Favorite {
	setFavoriteCount = async () => {
		const response = await http.post<any, ResponseBaseType<any>>(
			'/favorite/count',
			{}
		);
		return response;
	};

	setFavorite = async (data: SetFavoriteType) => {
		const response = await http.post<any, ResponseBaseType<any>>(
			'/favorite/add',
			data
		);
		return response;
	};

	removeFavorite = async (data: RemoveFavoriteType) => {
		const response = await http.post<any, ResponseBaseType<any>>(
			'/favorite/remove',
			data
		);
		return response;
	};

	deleteFavoriteItem = async (id: string | number) => {
		const response = await http.post<any, ResponseBaseType<any>>(
			`/favorite/remove/${id}?id=${id}`,
			{}
		);
		return response;
	};

	deleteFavoriteList = async (data: DeleteFavoriteType) => {
		const response = await http.post<any, ResponseBaseType<any>>(
			'/favorite/remove/batch',
			data
		);
		return response;
	};

	searchFavorite = async (data: SearchFavoriteType) => {
		const response = await http.post<any, ResponseBaseType<any>>(
			`/favorite/search?pageNo=${data?.pageNo}&pageSize=${data?.pageSize}`,
			{ key: data?.key }
		);
		return response;
	};

	queryFavoriteList = async (data: any) => {
		const response = await http.post<any, ResponseBaseType<any>>(
			'/favorite/check',
			data
		);
		return response;
	};

	fetchFavoriteListJp = async (data: FavoriteListJpType) => {
		const response = await http.post<FavoriteListJpType, ResponseBaseType<any>>(
			`/content/favourite/list?pageNo=${data.pageNo}&pageSize=${data.pageSize}`,
			data
		);
		return response;
	};

	fetchFavoriteReadJp = async (data: FavoriteReadJpType) => {
		const response = await http.post<FavoriteReadJpType, ResponseBaseType<any>>(
			'/content/read',
			data
		);
		return response;
	};

	fetchRemoveFavoriteJp = async (data: FavoriteReadJpType) => {
		const response = await http.post<FavoriteReadJpType, ResponseBaseType<any>>(
			'/content/favourite',
			data
		);
		return response;
	};
}

export const favorite = new Favorite();
