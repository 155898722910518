import { Select as AntSelect } from 'antd';
import { SelectProps, OptionProps } from 'antd/lib/select';
import { OptGroupProps } from 'rc-select/lib/OptGroup';

const { Option: AntOption, OptGroup: AntOptGroup } = AntSelect;

const Select = (props: SelectProps) => {
	return <AntSelect {...props}>{props.children}</AntSelect>;
};

const Option = (props: OptionProps) => {
	return <AntOption {...props}>{props.children}</AntOption>;
};

const OptGroup = (props: OptGroupProps) => {
	return <AntOptGroup {...props}>{props.children}</AntOptGroup>;
};

Select.Option = Option;
Select.OptGroup = OptGroup;

export { Select, type SelectProps, type OptionProps };
