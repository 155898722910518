import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { SearchBar, SearchProps } from '@/components';
import { DefaultOptionType } from 'antd/lib/select';
import { useNavigate } from 'react-router-dom';
import type { MenuProps } from 'antd';
import { Badge } from 'antd';
import _ from 'lodash';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { setSearchCache } from '@/stores/reducers/search';
import { suggestResType, searchService } from '@/services/search.service';
import { useGetSuggest } from '@/hooks/search.hook';
import { useGetCount } from '@/hooks/shoppingCart.hook';
import { useFavoriteCount } from '@/hooks/favorite.hook';
import { ResponseBaseType } from '@/core/services/http.service';
import { setLang, setAccount } from '@/stores/reducers/user';
import { myLoginService } from '@/services/login.service';
import { t } from '@/core/hooks/useTranslation';
import { useSearchParams } from 'react-router-dom';
import { setFavoriteNum } from '@/stores/reducers/favorite';
import { useGetCurrentUserInfo } from '@/hooks/user.hook';
import { setRegion } from '@/stores/reducers/user';

import Nav from './Nav';

const MenuItems = (favoriteNum?: any): MenuProps['items'] => [
	{
		label: t('home.Home'),
		key: '/',
	},
	{
		label: t('home.QuickOrder'),
		key: '/quick-order',
	},
	{
		label: t('home.MyOrder'),
		key: '/my-order',
	},
	{
		label: (
			<div>
				<Badge
					count={favoriteNum}
					size='small'
					offset={[1, 0]}
					className='px-2'
				>
					{t('MyFavorite.MyFavorite')}
				</Badge>
			</div>
		),
		key: '/my-favorite',
	},
	{
		label: t('home.MyQuotations'),
		key: '/my-quotations',
	},
	{
		label: t('home.ContactUs'),
		key: '/Contact-us',
	},
];

const accountData: MenuProps['items'] = [
	{
		label: 'Order Dashboard',
		key: '/order-dashboard',
	},
	{
		label: 'Profile & Account',
		key: '/profile-account',
	},
	{
		label: 'Security',
		key: '/security',
	},
];

const checkoutToken: MenuProps['items'] = [
	{
		label: 'GIGYA',
		key: 'GIGYA',
	},
	{
		label: 'COOKIE',
		key: 'COOKIE',
	},
];

export const Header = () => {
	const navigate = useNavigate();
	const searchCache = useSelector<any>(
		(statusbar) => statusbar.search.searchCache
	);
	const { data: userData } = useGetCurrentUserInfo();
	const { data: favoriteCount } = useFavoriteCount();

	const favoriteNum = useSelector<any>((item) => item.favorite.favoriteNum);

	const { data: cartCount } = useGetCount();

	const cartCountData = cartCount as unknown as ResponseBaseType<number>;

	const dispatch = useDispatch();

	const isClearSearchVal = useRef(false);
	const [searchParams] = useSearchParams();
	const value = searchParams.get('searchVal');

	const [autoVal, setAutoVal] = useState<DefaultOptionType['value']>(null);
	const [searchVal, setSearchVal] = useState<string | undefined>(undefined);
	const [headerIsFixed, setHeaderIsFixed] = useState<boolean>(false);
	const [selectedKeys, setSelectedKeys] = useState(['/']);
	const [tokenType, setTokenType] = useState(myLoginService.getTokenType());

	const lang = useSelector<any>((state) => state?.user?.lang) as any;

	useEffect(() => {
		dispatch(setFavoriteNum(favoriteCount?.data));
	}, [favoriteCount]);

	const productLine: any = useSelector<any>((item) => item.search.productLine);

	const { data: searchResultData, refetch } = useGetSuggest({
		keyword: searchVal,
		productLine: productLine == 'all' ? null : productLine,
	});

	useEffect(() => {
		if (
			userData &&
			userData?.data &&
			userData?.data?.userDetails &&
			!_.isEmpty(userData?.data?.userDetails?.soldToAddress)
		) {
			dispatch(
				setAccount(userData?.data?.userDetails?.soldToAddress?.currency)
			);
		}

		if (
			userData &&
			userData?.data &&
			userData?.data?.userDetails &&
			!_.isEmpty(userData?.data?.userDetails?.userBO)
		) {
			const country = _.get(
				_.get(_.get(_.get(userData, 'data'), 'userDetails'), 'userBO'),
				'country'
			);
			dispatch(setRegion(country));
		}
	}, [userData]);

	const changeLang = () => {
		dispatch(setLang(lang == 'cn' ? 'en' : 'cn'));
	};

	useEffect(() => {
		if (_.find(MenuItems(favoriteNum), { key: window.location.pathname })) {
			setSelectedKeys([window.location.pathname]);
		} else {
			setSelectedKeys([]);
		}
	}, [window.location.pathname]);

	useEffect(() => {
		setAutoVal(value);
	}, [value]);

	const onSubmit = useCallback<Exclude<SearchProps['onSubmit'], undefined>>(
		(val: any, selectValue: any) => {
			if (!val) return;
			searchService
				.getSuggest({
					keyword: val,
					productLine: selectValue == 'all' ? null : selectValue,
				})
				.then((res) => {
					if (res && res.length > 0) {
						if (res[0].sku == val) {
							dispatch(
								setSearchCache({
									skuName: res[0]?.skuName,
									sku: val,
								})
							);
						} else {
							dispatch(
								setSearchCache({
									sku: val,
								})
							);
						}
					} else {
						dispatch(
							setSearchCache({
								sku: val,
							})
						);
					}
				});

			navigate(`/product-search?${encodeURI(`searchVal=${val}`)}`);
			isClearSearchVal.current = true;
		},
		[]
	);

	const autoCompleteOnchange = useCallback<
		Exclude<SearchProps['autoCompleteOnchange'], undefined>
	>((val) => {
		setAutoVal(val);

		if (!val || val.length < 2) return;

		setSearchVal(val);
	}, []);

	const completeClick = useCallback((res: suggestResType) => {
		dispatch(
			setSearchCache({
				skuName: res.skuName,
				sku: res.sku,
			})
		);

		navigate(`/product-search?${encodeURI(`searchVal=${res.sku}`)}`);

		isClearSearchVal.current = true;
	}, []);

	const autoCompleteDefaultOptions = useMemo<
		SearchProps['autoCompleteDefaultOptions']
	>(() => {
		const data = searchCache as suggestResType[];

		if (!data || !data.length || autoVal) return undefined;

		return [
			{
				label: t('SearchJp.searchHistory'),
				options: data.map((res) => ({
					label: (
						<div onClick={() => completeClick(res)}>
							<p>{res.sku}</p>
							{/* <p>{res.skuName}</p> */}
						</div>
					),
					value: res.sku,
				})),
			},
		];
	}, [searchCache, autoVal]);

	const autoCompleteOptions = useMemo<
		SearchProps['autoCompleteOptions']
	>(() => {
		if (!searchResultData || !searchResultData.length || !autoVal)
			return autoCompleteDefaultOptions;

		return searchResultData.map((res) => ({
			label: (
				<div onClick={() => completeClick(res)}>
					<p>{res.sku}</p>
					<p>{res.skuName}</p>
				</div>
			),
			value: res.sku,
		}));
	}, [searchResultData, autoVal, completeClick, autoCompleteDefaultOptions]);

	const navItemOnClick = useCallback<Exclude<MenuProps['onClick'], undefined>>(
		(v) => {
			setAutoVal('');
			setSearchVal('');
			navigate(v.key);
			setSelectedKeys([v.key]);
		},
		[]
	);

	const headerFixed = useCallback(() => {
		const isFixed = document.documentElement.scrollTop > 160;
		setHeaderIsFixed(isFixed);

		document.documentElement.style.marginTop = isFixed ? '140px' : '0';
	}, []);

	const accountClick = useCallback<Exclude<MenuProps['onClick'], undefined>>(
		(val) => {
			setAutoVal('');
			setSearchVal('');
			navigate(val.key);
		},
		[]
	);

	const clickIcon = useCallback<Exclude<MenuProps['onClick'], undefined>>(
		(val) => {
			myLoginService.setTokenType(val.key);
			setTokenType(val.key);
		},
		[]
	);

	useEffect(() => {
		if (!searchVal) return;

		refetch();
	}, [searchVal]);

	useEffect(() => {
		document.addEventListener('scroll', headerFixed);

		return () => {
			document.removeEventListener('scroll', headerFixed);
		};
	}, []);
	return (
		<div
			className={cn(
				'flex justify-center shadow-lg bg-white',
				headerIsFixed ? 'fixed top-0 left-0 w-full z-50' : ''
			)}
		>
			<div className='px-8 pb-5 w-full'>
				<Nav
					navItems={MenuItems(favoriteNum)}
					navItemOnClick={navItemOnClick}
					catNum={cartCountData?.data}
					selectedKeys={selectedKeys}
					accountItems={accountData}
					accountClick={accountClick}
					setAutoVal={setAutoVal}
					clickIcon={clickIcon}
					tokenType={tokenType || ''}
					checkoutToken={checkoutToken}
					changeLang={changeLang}
				/>
				<SearchBar
					autoCompleteDefaultOptions={autoCompleteDefaultOptions}
					setAutoVal={setAutoVal}
					autoVal={autoVal}
					onSubmit={onSubmit}
					autoCompleteOnchange={_.debounce(autoCompleteOnchange, 300)}
					autoCompleteOptions={autoCompleteOptions}
					isClearSearchVal={isClearSearchVal.current}
					clearValCallback={() => (isClearSearchVal.current = false)}
				/>
			</div>
		</div>
	);
};
