import { createSlice } from '@reduxjs/toolkit';

interface UserState {
	menuData: any[];
}

const initialState = {
	menuData: [],
} as UserState;

const userJp = createSlice({
	name: 'userJp',
	initialState,
	reducers: {
		setMenuData(state, action) {
			state.menuData = action.payload;
		},
	},
});

export const { setMenuData } = userJp.actions;
export default userJp.reducer;
