import { useQuery } from '@tanstack/react-query';
import { user } from '@/services/user.service';
import { useSelector } from 'react-redux';

export enum UserServiceEnum {
	getCurrentUserInfo = 'getCurrentUserInfo',
	getSiteSelector = 'getSiteSelector',
}

export enum AccountEnum {
	USD = '$',
	INR = '₹',
	SGD = 'S$',
}

export const useGetCurrentUserInfo = () =>
	useQuery([UserServiceEnum.getCurrentUserInfo], () =>
		user.getCurrentUserInfo()
	);

export const useGetSiteSelector = () =>
	useQuery([UserServiceEnum.getSiteSelector], () => user.getSiteSelector());

export const useAccount = () => {
	const accountKey = useSelector<any>(
		(statusbar) => statusbar.user.currentAccount
	);

	const data = accountKey as 'USD' | 'INR' | 'SGD';

	return accountKey ? AccountEnum[data] : AccountEnum.USD;
};
