import { FC, useCallback, useEffect } from 'react';
import { SelectProps, DefaultOptionType } from 'antd/lib/select';
import { AutoCompleteProps } from 'antd/lib/auto-complete';
import { Button, AutoComplete, Select, Input } from '@/components';
import styled from '@emotion/styled';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { t } from '@/core/hooks/useTranslation';
import { getuser } from '@/services/userJp.service';

type selectOptionsType = SelectProps['options'];

export interface SearchProps {
	selectOptions?: selectOptionsType;
	placeholder?: string;
	onSubmit?: (
		value: DefaultOptionType['value'],
		selectVal: DefaultOptionType['value']
	) => void;
	autoCompleteOptions?: AutoCompleteProps['options'];
	autoCompleteDefaultOptions?: AutoCompleteProps['options'];
	autoCompleteOnchange?: AutoCompleteProps['onChange'];
	isClearSearchVal?: boolean;
	clearValCallback?: () => void;
	setAutoVal: (value: any) => void;
	autoVal: any;
}

const defaultSelectOptions: selectOptionsType = [
	{
		label: 'Search all',
		value: 'all',
	},
	{
		label: 'LED',
		value: 'LED',
	},
	{
		label: 'LCD',
		value: 'LCD',
	},
	{
		label: 'EPD',
		value: 'EPD',
	},
	{
		label: 'CMD',
		value: 'CMD',
	},
	{
		label: 'CCG',
		value: 'CCG',
	},
	{
		label: 'CAD',
		value: 'CAD',
	},
	{
		label: 'BID',
		value: 'BID',
	},
	{
		label: 'APD',
		value: 'APD',
	},
];

const StyleSelect = styled(Select)`
	width: 263px;
	.ant-select-selector {
		background: #f5f5f5 !important;
		border-right: none !important;
	}
`;

export const SearchBar: FC<SearchProps> = ({
	selectOptions,
	placeholder,
	onSubmit,
	autoCompleteDefaultOptions,
	autoCompleteOptions,
	autoCompleteOnchange,
	isClearSearchVal,
	clearValCallback,
	setAutoVal,
	autoVal,
}) => {
	const dispatch = useDispatch();

	const productLine: any = useSelector<any>((item) => item.search.productLine);
	const [searchParams] = useSearchParams();
	const searchVal = searchParams.get('searchVal');

	useEffect(() => {
		if (!searchVal || !searchVal.length) return;
		setAutoVal(searchVal);
	}, [searchVal]);

	const onSelectChange = useCallback<
		Exclude<SelectProps['onChange'], undefined>
	>((e) => {
		getuser.setProductLine(e);
	}, []);

	const onClick = useCallback(() => {
		onSubmit && onSubmit(autoVal, productLine);
		isClearSearchVal = true;
	}, [productLine, autoVal, isClearSearchVal]);

	useEffect(() => {
		if (!selectOptions || !selectOptions.length) return;

		getuser.setProductLine(selectOptions[0].value);
	}, [selectOptions]);

	const autoOnChange = useCallback<
		Exclude<AutoCompleteProps['onChange'], undefined>
	>((e, options) => {
		setAutoVal(e);
		autoCompleteOnchange && autoCompleteOnchange(e, options);
	}, []);

	const onFocus = useCallback<
		Exclude<AutoCompleteProps['onFocus'], undefined>
	>(() => {
		if (!isClearSearchVal) return;

		clearValCallback && clearValCallback();

		setAutoVal(null);
		autoCompleteOnchange &&
			autoCompleteOnchange(null, {
				label: '',
			});
	}, [isClearSearchVal, clearValCallback]);
	return (
		<div className='flex justify-between'>
			<StyleSelect
				size='large'
				options={selectOptions || defaultSelectOptions}
				value={productLine || defaultSelectOptions[0].value}
				onChange={onSelectChange}
				className='text-center custom-select'
			/>
			<div className='flex-1'>
				<AutoComplete
					options={autoCompleteOptions || autoCompleteDefaultOptions}
					className='w-full'
					onChange={autoOnChange}
					onFocus={onFocus}
					value={autoVal}
					filterOption={false}
				>
					<Input
						size='large'
						onPressEnter={onClick}
						placeholder={
							placeholder ||
							'Search by catalog number, product name, keyword, application'
						}
					/>
				</AutoComplete>
			</div>
			<Button type='primary' size='large' onClick={onClick} className='w-36'>
				{t('home.Search')}
			</Button>
		</div>
	);
};
