import icon from '@/assets/icons';

export const GetProductFamily = (
	type: number | string,
	clickIcon?: (value?: any) => void,
	getStyle?: (value?: any) => string,
	lang?: string
) => {
	if (type == '1') {
		return (
			<img
				src={icon.machine1}
				className={getStyle && getStyle()}
				onClick={clickIcon}
			/>
		);
	} else if (type == '2') {
		return (
			<img
				src={lang =='en' ? icon.machine2:icon.machineJP}
				className={getStyle && getStyle()}
				onClick={clickIcon}
			/>
		);
	} else {
		return (
			<img
				src={icon.machine1}
				className={getStyle && getStyle()}
				onClick={clickIcon}
			/>
		);
	}
};
