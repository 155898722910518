import { createSlice } from '@reduxjs/toolkit';

export interface CheckOutState {
	checkoutInfo: any;
}

const initialState = { checkoutInfo: {} } as CheckOutState;

const checkout = createSlice({
	name: 'checkout',
	initialState,
	reducers: {
		setCheckoutInfo(state, action) {
			state.checkoutInfo = action.payload;
		},
	},
});

export const { setCheckoutInfo } = checkout.actions;
export default checkout.reducer;
