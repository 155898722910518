export const country = (): string => {
	return process.env.REACT_APP_COUNTRY ?? '';
};

export const lang = (): string => {
	return process.env.REACT_APP_COUNTRY === 'China'
		? 'cn'
		: process.env.REACT_APP_COUNTYRY === 'jp'
		? 'en'
		: 'en';
};
