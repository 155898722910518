import React  from 'react';
import { Modal,Button } from '@/components';
import { t } from '@/core/hooks/useTranslation';

interface ShowPDFType {
	hideModal: () => void;
	open: boolean;
};

export const ShowPDF = (props:ShowPDFType) => {

return (
    <Modal
          title={`Partner Portal ${t('LoginJp.Terms_of_Use')}`}
          open={props?.open}
          onOk={props?.hideModal}
          onCancel={props?.hideModal}
          cancelText={t('UserAdmin.Cancel')}
          width={800}
          style={{ top: 20 }}
          footer={<div>
            <Button onClick={props?.hideModal} type="primary">{t('UserAdmin.OK')}</Button>
          </div>}
          >
          <iframe src={`${process.env.REACT_APP_LOGIN_PDF_URL}`} width='100%' height={'400px'} className='mt-2'/>
        </Modal>
  );
};