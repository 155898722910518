import { createSlice } from '@reduxjs/toolkit';

export interface SearchCacheType {
	sku?: string;
	skuName?: string;
}

export interface SearchState {
	searchCache: SearchCacheType[];
	productLine?: string | null;
}

const initialState = {
	searchCache: [],
	productLine: null,
} as SearchState;

const search = createSlice({
	name: 'search',
	initialState,
	reducers: {
		setSearchCache(state, action) {
			const result = state?.searchCache.find(
				(res) => res.sku === action.payload.sku
			);

			if (result) return;

			state?.searchCache.unshift(action.payload);

			if (state?.searchCache.length > 10) {
				state.searchCache.length = 10;
			}
		},
	},
});

export const { setSearchCache } = search.actions;
export default search.reducer;
