// Inspired by react-multi-lang
import { useEffect, useState } from 'react';

const useForceUpdate = () => {
	const setState = useState(true)[1];
	return () => setState((n) => !n);
};

let language = 'pt';
let id = 1;

type Subscription = () => any;

const subscribes: {
	[key: string]: Subscription;
} = {};

interface ITranslation {
	[key: string]: string | ITranslation;
}

export interface ITranslationParams {
	[key: string]: string;
}

export interface ITranslations {
	[key: string]: ITranslation;
}

export interface ITranslate {
	t(path: string, args?: { [key: string]: string }): string;
}

let translations: ITranslations = {};

export function subscribe(cb: Subscription): number {
	const newId = id;
	subscribes[newId] = cb;
	id += 1;
	return newId;
}

export function unsubscribe(componentId: number): void {
	delete subscribes[componentId];
}

function triggerSubscriptions() {
	Object.keys(subscribes).forEach((componentId: string) => {
		new Promise((resolve) => {
			if (
				subscribes[componentId] &&
				typeof subscribes[componentId] === 'function'
			) {
				subscribes[componentId]();
			}
			resolve(componentId);
		}).then();
	});
}

export function setDefaultLanguage(lang: string): void {
	language = lang;
}

export function setDefaultTranslations(userTranslations: ITranslations): void {
	if (Object.keys(translations).length !== 0) {
		setTranslations(userTranslations);
		return;
	}
	translations = userTranslations;
}

export function setTranslations(userTranslations: ITranslations): void {
	translations = userTranslations;
	triggerSubscriptions();
}

export function setLanguage(lang: string) {
	language = lang;
	triggerSubscriptions();
}

export function getLanguage(): string {
	return language;
}

export function t(path: string, args?: ITranslationParams): string {
	const translationKeys: string[] = path.split('.');
	let translation = '';
	if (translations[language]) {
		let translationObj: ITranslation = translations[language];

		translationKeys.forEach((key: string) => {
			const temp: string | ITranslation = translationObj[key];
			if (typeof translationObj[key] === 'object') {
				translationObj = translationObj[key] as ITranslation;
			}
			if (typeof temp === 'string') {
				translation = temp;
			}
		});

		if (translation) {
			if (args) {
				Object.keys(args).forEach((key) => {
					translation = translation.replace(`{${key}}`, args ? args[key] : '');
				});
			}
			return translation;
		}
	}
	return path;
}

export function useTranslation(basePath?: string) {
	const forceUpdate = useForceUpdate();
	useEffect(() => {
		const subId = subscribe(() => forceUpdate());
		return () => unsubscribe(subId);
	}, [forceUpdate]);
	return (path: string, args?: ITranslationParams) =>
		t(basePath ? basePath + '.' + path : path, args);
}
