import { DatePicker as AntDatePicker } from 'antd';

const { RangePicker: AntRangePicker } = AntDatePicker;

export const DatePicker = (props: any) => {
	return <AntDatePicker {...props}></AntDatePicker>;
};

const RangePicker = (props: any) => {
	return <AntRangePicker {...props}>{props.children}</AntRangePicker>;
};

DatePicker.RangePicker = RangePicker;
