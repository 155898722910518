import { createSlice } from '@reduxjs/toolkit';

export interface SearchCacheType {
	item?: string;
}

export interface SearchState {
	searchCache: SearchCacheType[];
	searchType?: string | null;
	productLineId?: number;
}

const initialState = {
	searchCache: [],
	searchType: 'all',
	productLineId: 1,
} as SearchState;

const searchJp = createSlice({
	name: 'search',
	initialState,
	reducers: {
		setSearchCache(state, action) {
			const result = state?.searchCache.find(
				(res) => res.item === action.payload.item
			);

			if (result) return;

			state?.searchCache.unshift(action.payload);

			if (state?.searchCache.length > 10) {
				state.searchCache.length = 10;
			}
		},

		setSearchType(state, action) {
			state.searchType = action.payload;
		},
	},
});

export const { setSearchCache, setSearchType } = searchJp.actions;
export default searchJp.reducer;
