import { http, ResponseBaseType } from '@/core/services/http.service';
class Permission {
	isAdminShowApi = async (userId: any) => {
		const response = await http.get<any, ResponseBaseType<any>>(
			`/role/isAdminShow?userId=${userId}`
		);
		return response;
	};
	isUserManageShowApi = async (userId: any) => {
		const response = await http.get<any, ResponseBaseType<any>>(
			`/role/isUserManageShow?userId=${userId}`
		);
		return response;
	};
	isContentManageShowApi = async (userId: any) => {
		const response = await http.get<any, ResponseBaseType<any>>(
			`/role/isContentManageShow?userId=${userId}`
		);
		return response;
	};
	getPermissionApi = async (userId: any) => {
		const response = await http.get<any, ResponseBaseType<any>>(
			`/role/getPermission?userId=${userId}`
		);
		return response;
	};
	addContentTrackApi = async (data: any) => {
		const response = await http.post<any, ResponseBaseType<any>>(
			'/track/addContentTrack',
			data
		);
		return response;
	};
	addLoginTrackApi = async (data: any) => {
		const response = await http.post<any, ResponseBaseType<any>>(
			'/track/addLoginTrack',
			data
		);
		return response;
	};
}

export const permissionJpService = new Permission();
