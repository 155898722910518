import { Checkbox as AntCheckbox } from 'antd';
import { CheckboxProps, CheckboxGroupProps } from 'antd/lib/checkbox';
import { FC } from 'react';

const { Group: CheckoutGroup } = AntCheckbox;

export const Checkbox: FC<CheckboxProps> = (props) => {
	return <AntCheckbox {...props}>{props.children}</AntCheckbox>;
};

export const CheckboxGroup: FC<CheckboxGroupProps> = (props) => {
	return <CheckoutGroup {...props}>{props.children}</CheckoutGroup>;
};
