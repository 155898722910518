import styled from '@emotion/styled';
import { FC } from 'react';
import { useAccount } from '@/hooks/user.hook';

const ItemBox = styled('div')`
	width: 230px;
	margin-left: 13px;
	&:last-child: {
		margin-left: 0;
	}
`;

const ImgBox = styled('div')`
	height: 230px;
	width: 230px;
`;

export interface ProductItemProps {
	id: number | string;
	imgUrl?: string;
	title?: string;
	subTitle?: string;
	catalogNum?: number;
	untilSize?: string;
	price?: number | string;
}

export const ProductItem: FC<ProductItemProps> = ({
	title,
	imgUrl,
	subTitle,
	catalogNum,
	untilSize,
	price,
}) => {
	const account = useAccount();
	return (
		<ItemBox className='border border-solid border-gray-100'>
			<ImgBox>
				<img className='w-full h-full' src={imgUrl} />
			</ImgBox>
			<div className='p-3'>
				<div className='text-base text-gray-800 font-semibold'>{title}</div>
				<div className='text-base text-gray-800 font-semibold'>{subTitle}</div>
				<div className='py-3 text-sm text-gray-400'>
					<span className='font-semibold'>Catalog Number</span>：
					<span>{catalogNum}</span>
				</div>
				<div className='pb-3 text-sm text-gray-400'>
					<span className='font-semibold'>Unit Size</span>：
					<span>{untilSize}</span>
				</div>
				<div className='flex justify-between'>
					<div className='text-primary text-base'>
						Your Price：{account}
						{price}
					</div>
					<div className='w-6 h-6 rounded-xl bg-primary flex justify-center cursor-pointer'>
						<span className='icon-16-plus-mono text-white mt-1'></span>
					</div>
				</div>
			</div>
		</ItemBox>
	);
};
