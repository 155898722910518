import { Modal as AntModal } from 'antd';
import { ModalProps } from 'antd/lib/modal';

export const Modal = (props: ModalProps) => {
	return <AntModal {...props}>{props.children}</AntModal>;
};

Modal.confirm = AntModal.confirm;
Modal.error = AntModal.error;
Modal.success = AntModal.success;
