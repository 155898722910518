import { http, ResponseBaseType } from '@/core/services/http.service';

export interface suggestParamsType {
	keyword?: string;
	productLine?: string | null | undefined;
}

export interface suggestResType {
	sku: string;
	skuName: string;
}

export interface searchParamsType {
	keyword: string | null;
	pageNo?: number;
	pageSize?: number;
	productLine?: string | null;
}

export interface viewCartResItemComponentItemType {
	quantity: number;
	sku: string;
	skuName: string;
}

export interface PriceType {
	contractPrice: number;
	contractTaxesPrice: number;
	currency: string;
	discount: number;
	listPrice: number;
	taxes: number;
	totalContractPrice: number;
	totalContractTaxesPrice: number;
	totalDiscount: number;
	totalListPrice: number;
	totalTaxes: number;
}

export interface StockType {
	backOrdDate: string;
	backOrdQuantity: number;
	inStockDate: string;
	inStockQuantity: number;
	shippingDate: string;
}

export interface viewCartResItemType {
	componentList: viewCartResItemComponentItemType[];
	discontinue: number;
	entitlement: number;
	id: number;
	price: PriceType;
	productLine: number;
	quantity: number;
	sku: string;
	skuName: string;
	stock: StockType;
	uom: string;
}

class SearchService {
	getSuggest = async (params: suggestParamsType) => {
		const response = await http.get<
			suggestParamsType,
			ResponseBaseType<suggestResType[]>
		>('/search/suggest', {
			params,
		});
		return response.data;
	};

	getSearch = async (params: searchParamsType) => {
		const response = await http.get<
			searchParamsType,
			ResponseBaseType<viewCartResItemType[]>
		>('/search/search', {
			params,
		});
		return response;
	};

	getSearchPrice = async (data: Array<any>) => {
		const response = await http.post<Array<any>, ResponseBaseType<any>>(
			'/price/queryPriceInfo',
			data
		);
		return response;
	};
}

export const searchService = new SearchService();
