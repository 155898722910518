import { useQuery, useMutation } from '@tanstack/react-query';
import {
	favorite,
	SetFavoriteType,
	RemoveFavoriteType,
	DeleteFavoriteType,
	SearchFavoriteType,
	FavoriteListJpType,
	FavoriteReadJpType,
} from '@/services/favorite.service';

export enum FavoriteServerStateKeysEnum {
	setFavorite = 'setFavorite',
	removeFavorite = 'removeFavorite',
	deleteFavoriteItem = 'deleteFavoriteItem',
	deleteFavoriteList = 'deleteFavoriteList',
	setFavoriteCount = 'setFavoriteCount',
	searchFavorite = 'searchFavorite',
	queryFavoriteType = 'queryFavoriteType',
	favoriteListJp = 'favoriteListJp',
	favoriteMuteListJp = 'favoriteMuteListJp',
	favoriteReadJp = 'favoriteReadJp',
	favoriteMuteReadJp = 'favoriteMuteReadJp',
	favoriteRemoveJp = 'favoriteRemoveJp',
	favoriteMuteRemoveJp = 'favoriteMuteRemoveJp',
}

export const useFavoriteCount = () => {
	return useQuery([FavoriteServerStateKeysEnum.setFavoriteCount], () =>
		favorite.setFavoriteCount()
	);
};

export const useSetFavorite = () => {
	return useMutation({
		mutationKey: [FavoriteServerStateKeysEnum.setFavorite],
		mutationFn: (data: SetFavoriteType) => favorite.setFavorite(data),
	});
};

export const useRemoveFavorite = () => {
	return useMutation({
		mutationKey: [FavoriteServerStateKeysEnum.removeFavorite],
		mutationFn: (data: RemoveFavoriteType) => favorite.removeFavorite(data),
	});
};

export const useDeleteFavoriteItem = () => {
	return useMutation({
		mutationKey: [FavoriteServerStateKeysEnum.deleteFavoriteList],
		mutationFn: (id: number | string) => favorite.deleteFavoriteItem(id),
	});
};

export const useDeleteFavoriteList = () => {
	return useMutation({
		mutationKey: [FavoriteServerStateKeysEnum.deleteFavoriteList],
		mutationFn: (data: DeleteFavoriteType) => favorite.deleteFavoriteList(data),
	});
};

export const useSearchFavorite = () => {
	return useMutation({
		mutationKey: [FavoriteServerStateKeysEnum.searchFavorite],
		mutationFn: (data: SearchFavoriteType) => favorite.searchFavorite(data),
	});
};

export const useQueryFavoriteType = () => {
	return useMutation({
		mutationKey: [FavoriteServerStateKeysEnum.queryFavoriteType],
		mutationFn: (data: any) => favorite.queryFavoriteList(data),
	});
};

export const useFetchFavoriteListJp = (data: FavoriteListJpType) => {
	return useQuery([FavoriteServerStateKeysEnum.favoriteListJp], () =>
		favorite.fetchFavoriteListJp(data)
	);
};

export const useMuteFetchFavoriteListJp = () => {
	return useMutation({
		mutationKey: [FavoriteServerStateKeysEnum.favoriteMuteListJp],
		mutationFn: (data: FavoriteListJpType) =>
			favorite.fetchFavoriteListJp(data),
	});
};

export const useFetchFavoriteReadJp = (data: FavoriteReadJpType) => {
	return useQuery([FavoriteServerStateKeysEnum.favoriteReadJp], () =>
		favorite.fetchFavoriteReadJp(data)
	);
};

export const useMuteFetchFavoriteReadJp = () => {
	return useMutation({
		mutationKey: [FavoriteServerStateKeysEnum.favoriteMuteReadJp],
		mutationFn: (data: FavoriteReadJpType) =>
			favorite.fetchFavoriteReadJp(data),
	});
};

export const useFetchRemoveFavoriteJp = (data: FavoriteReadJpType) => {
	return useQuery([FavoriteServerStateKeysEnum.favoriteRemoveJp], () =>
		favorite.fetchRemoveFavoriteJp(data)
	);
};

export const useMuteFetchRemoveFavoriteJp = () => {
	return useMutation({
		mutationKey: [FavoriteServerStateKeysEnum.favoriteMuteRemoveJp],
		mutationFn: (data: FavoriteReadJpType) =>
			favorite.fetchRemoveFavoriteJp(data),
	});
};
