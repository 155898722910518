import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import icon from '@/assets/icons';
import { Modal, Button } from '@/components';
import styled from '@emotion/styled';
import { t } from '@/core/hooks/useTranslation';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import includes from 'lodash/includes';
import size from 'lodash/size';
import head from 'lodash/head';
import get from 'lodash/get';
import toUpper from 'lodash/toUpper';
import { publicUrl } from '@/core/utils';
import { getuser } from '@/services/userJp.service';

interface ButtonProps {
	isCheck: number;
	dataOnCheck: number;
}

const CheckButton = styled.div<ButtonProps>`
	background-color: ${(props) =>
		props.isCheck == props['dataOnCheck'] ? '#FFEEEE' : '#F7F7F7'};
	color: ${(props) =>
		props.isCheck == props['dataOnCheck'] ? '#E71316' : '#555759'};
`;

type ModalBoxProps = {
	isModalOpen: boolean;
	setIsModalOpen: (value: boolean) => void;
};

const ModalBox = React.memo((props: ModalBoxProps) => {
	const productLineId: any = getuser.getProductLine();
	const [productLine, setProductLine] = useState(productLineId);
	const menu = useSelector<any>((state) => state?.userJp?.menuData) as any;

	const clickCheckButton = useCallback((id: number) => {
		setProductLine(id);
	}, []);

	const handleOk = () => {
		if (productLine == 1) {
			window.open('/form-inquiry-FTIR');
		} else {
			window.open('/form-inquiry-UVVIS');
		}
		props?.setIsModalOpen(false);
	};

	return (
		<Modal
			maskTransitionName=''
			transitionName=''
			title={
				<div className='font-normal'>
					引合を登録する製品群をお選びください。
				</div>
			}
			open={props?.isModalOpen}
			onOk={handleOk}
			onCancel={() => {
				props?.setIsModalOpen(false);
			}}
			destroyOnClose={true}
			width={400}
			key={1}
			footer={() => (
				<div className='flex flex-row justify-end items-center'>
					<Button
						className='rounded-lg'
						onClick={() => props?.setIsModalOpen(false)}
					>
						{t('CommonJp.cancel')}
					</Button>
					<Button className='rounded-lg ml-2' type='primary' onClick={handleOk}>
						{t('UserAdmin.OK')}
					</Button>
				</div>
			)}
		>
			<div className='flex my-5'>
				{menu && !isEmpty(menu)
					? map(menu, (item) => (
							<CheckButton
								key={item?.id}
								isCheck={productLine}
								dataOnCheck={item?.id}
								className='mr-[1rem] rounded-2xl flex justify-center items-center px-[.7rem] py-[.2rem] cursor-pointer'
								onClick={() => {
									clickCheckButton(item?.id);
								}}
							>
								{item?.categoryName}
							</CheckButton>
					  ))
					: null}
			</div>
		</Modal>
	);
});

export const FormFloatButton = () => {
	const location = useLocation();

	const menu = useSelector<any>((state) => state?.userJp?.menuData) as any;
	const lang = useSelector<any>((state) => state?.user?.lang) as any;
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [showIcon, setShowIcon] = useState<boolean>(true);
	const [floatBottom, setFloatBottom] = useState<string>('200px');

	const FloatButtonBox = styled.div`
		bottom: ${floatBottom} !important;
		.expandable {
			height: 40px;
			transition: width 0.2s ease, transform 0.2s ease;
			position: relative;
			display: flex;
			align-items: center;
			transform-origin: center;
		}

		.expandable:hover {
			width: ${lang == 'en' ? '185px' : '158px'};
			justify-content: space-around;
			transform: ${lang == 'en' ? 'translateX(-78%)' : 'translateX(-74%)'};
			.text_contact {
				display: block;
			}
			.expandable img {
				margin-top: 5px;
			}
		}

		.text_contact {
			padding: 10px 0px 10px 7px;
			display: none;
		}
	`;
	useEffect(() => {
		if (
			includes(location.pathname, 'admin') ||
			includes(location.pathname, 'contact-us')
		) {
			setFloatBottom('150px');
			setShowIcon(true);
		} else if (includes(map(publicUrl, toUpper), toUpper(location.pathname))) {
			setShowIcon(false);
		} else {
			setFloatBottom('200px');
			setShowIcon(true);
		}
	}, [location.pathname]);

	const clickFloatButton = () => {
		if (size(menu) > 1) {
			setIsModalOpen(true);
		} else if (size(menu) == 1) {
			get(head(menu), 'id') == 1
				? window.open('/form-inquiry-FTIR')
				: window.open('/form-inquiry-UVVIS');
		}
	};

	return showIcon ? (
		<FloatButtonBox
			className={'fixed right-[calc(120px/2)]  z-30 cursor-pointer w-[40px]'}
		>
			<div
				className='bg-white rounded-full flex justify-center custom-top-shadow expandable'
				onClick={clickFloatButton}
			>
				<div className='text_contact'>{t('HomeJp.float_Forms')}</div>
				<img src={icon?.formFloatButton} />
			</div>

			{isModalOpen ? (
				<ModalBox isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
			) : null}
		</FloatButtonBox>
	) : null;
};
