import icon from '@/assets/icons';

export const GetFileIcon = (fileExt: any, className?: string) => {
	switch (fileExt) {
		case 'pdf':
			return <img src={icon.pdfIocn} className={className} />;
		case 'excel':
		case 'xls':
		case 'xlsx':
			return <img src={icon.excelIocn} className={className} />;
		case 'csv':
			return <img src={icon.file_icon_csv} className={className} />;
		case 'bmp':
			return <img src={icon.file_icon_bmp} className={className} />;
		case 'png':
		case 'jpg':
			return <img src={icon.file_icon_jpg} className={className} />;
		case 'doc':
		case 'docx':
			return <img src={icon.file_icon_word} className={className} />;
		case 'link':
		case 'href':
			return <img src={icon.file_icon_link} className={className} />;
		case 'earth':
			return <img src={icon.file_icon_earth} className={className} />;
		case 'mp4':
		case 'avi':
		case 'mov':
		case 'mkv':
			return <img src={icon.file_icon_video} className={className} />;
		case 'gif':
			return <img src={icon.file_icon_gif} className={className} />;
		case 'unknown':
			return <img src={icon.file_unknown} className={className} />;
		default:
			return <img src={icon.file_unknown} className={className} />;
	}
};
