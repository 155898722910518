import { Tabs as AntTabs } from 'antd';
import { TabsProps } from 'antd/lib/tabs';

export const Tabs = (props: TabsProps) => {
	return (
		<AntTabs {...props} moreIcon={null}>
			{props.children}
		</AntTabs>
	);
};
