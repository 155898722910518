import { useQuery, useMutation } from '@tanstack/react-query';
import { permissionJpService } from '@/services/permission.service';
import { useSelector } from 'react-redux';

export enum PermissionKeysEnum {
	isAdminShowApi = 'isAdminShowApi',
	isMuteAdminShowApi = 'isMuteAdminShowApi',
	isUserMngShowApi = 'isUserMngShowApi',
	isMuteUserMngShowApi = 'isMuteUserMngShowApi',
	isContentMngShowApi = 'isContentMngShowApi',
	isMuteContentMngShowApi = 'isMuteContentMngShowApi',
	useGetAllPermissions = 'useGetAllPermissions',
	useGetMuteAllPermissions = 'useGetMuteAllPermissions',
	useAddContentTrack = 'useAddContentTrack',
	useMuteAddContentTrack = 'useMuteAddContentTrack',
	useLoginTrack = 'useLoginTrack',
	useMuteLoginTrack = 'useMuteLoginTrack',
}

/**
 * @description: Permissions of user has "Admin" permission
 */
export const useGetIsAdminShowApi = () => {
	const userId = useSelector<any>((status) => status.user.userBo.id);
	return useQuery([PermissionKeysEnum.isAdminShowApi], () =>
		permissionJpService.isAdminShowApi(userId)
	);
};
export const useMuteIsAdminShowApi = () => {
	const userId = useSelector<any>((status) => status.user.userBo.id);
	return useMutation({
		mutationKey: [PermissionKeysEnum.isMuteAdminShowApi],
		mutationFn: () => permissionJpService.isAdminShowApi(userId),
	});
};

/**
 * @description: Permissions of user has "UserManagement" permission
 */
export const useGetIsUserMngShowApi = () => {
	const userId = useSelector<any>((status) => status.user.userBo.id);
	return useQuery([PermissionKeysEnum.isUserMngShowApi], () =>
		permissionJpService.isUserManageShowApi(userId)
	);
};
export const useIsMuteUserMngShowApi = () => {
	const userId = useSelector<any>((status) => status.user.userBo.id);
	return useMutation({
		mutationKey: [PermissionKeysEnum.isMuteUserMngShowApi],
		mutationFn: () => permissionJpService.isUserManageShowApi(userId),
	});
};

/**
 * @description: Permissions of user has "ContentManagement" permission
 */
export const useGetIsContentMngShowApi = () => {
	const userId = useSelector<any>((status) => status.user.userBo.id);
	return useQuery([PermissionKeysEnum.isContentMngShowApi], () =>
		permissionJpService.isContentManageShowApi(userId)
	);
};
export const useIsMuteContentMngShowApi = () => {
	const userId = useSelector<any>((status) => status.user.userBo.id);
	return useMutation({
		mutationKey: [PermissionKeysEnum.isMuteContentMngShowApi],
		mutationFn: () => permissionJpService.isContentManageShowApi(userId),
	});
};

/**
 * @description: Permissions of user has "All"
 */
export const useGetAllPermissions = (userId: any) => {
	return useQuery([PermissionKeysEnum.useGetAllPermissions], () =>
		permissionJpService.getPermissionApi(userId)
	);
};
export const useGetMuteAllPermissions = () => {
	return useMutation({
		mutationKey: [PermissionKeysEnum.useGetMuteAllPermissions],
		mutationFn: (userId: any) => permissionJpService.getPermissionApi(userId),
	});
};

/**
 * @description: Content download track
 */
export const useAddContentTrack = (data: any) => {
	return useQuery([PermissionKeysEnum.useAddContentTrack], () =>
		permissionJpService.addContentTrackApi(data)
	);
};
export const useMuteAddContentTrack = () => {
	return useMutation({
		mutationKey: [PermissionKeysEnum.useMuteAddContentTrack],
		mutationFn: (data: any) => permissionJpService.addContentTrackApi(data),
	});
};

/**
 * @description: Content download track
 */
export const useLoginTrack = (data: any) => {
	return useQuery([PermissionKeysEnum.useLoginTrack], () =>
		permissionJpService.addLoginTrackApi(data)
	);
};
export const useMuteLoginTrack = () => {
	return useMutation({
		mutationKey: [PermissionKeysEnum.useMuteLoginTrack],
		mutationFn: (data: any) => permissionJpService.addLoginTrackApi(data),
	});
};
