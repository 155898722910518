import { Tag } from 'antd';
import { t } from '@/core/hooks/useTranslation';

export const GetStatusTag = (status: number) => {
	switch (status) {
		case 1:
			return (
				<Tag
					color='success'
					className='rounded-2xl py-[2px] px-[7px] text-[.85rem]'
				>
					{t('UserAdmin.Active')}
				</Tag>
			);
			break;
		case 2:
			return (
				<Tag
					color='error'
					className='rounded-2xl py-[2px] px-[7px] text-[.85rem]'
				>
					{t('UserAdmin.Pending')}
				</Tag>
			);
			break;
		case 3:
			return (
					<Tag
						color='error'
						className='rounded-2xl py-[2px] px-[7px] text-[.85rem]'
					>
						{t('UserAdmin.Created')}
					</Tag>
				);
			break;
		case 4:
				return (
						<Tag
							color='error'
							className='rounded-2xl py-[2px] px-[7px] text-[.85rem]'
						>
							{t('UserAdmin.Notlogin')}
						</Tag>
					);
				break;
		case 0:
			return (
				<Tag
					color='warning'
					className='rounded-2xl py-[2px] px-[7px] text-[.85rem]'
				>
					{t('UserAdmin.Inactive')}
				</Tag>
			);
			break;
		default:
			return (
				<Tag
					color='success'
					className='rounded-2xl py-[2px] px-[7px] text-[.85rem]'
				>
					{t('UserAdmin.Active')}
				</Tag>
			);
	}
};
