import { http, ResponseBaseType } from '@/core/services/http.service';
import { viewCartResItemType } from './search.service';

export interface viewCartResType {
	id: number;
	itemList: viewCartResItemType[];
}

export interface reloadCartItemStockPriceParamsType {
	sku: string;
	uom: string;
	quantity: number;
}

export interface addItemToCartParamsType {
	quantity: number;
	sku: string;
	skuName: string;
	uom: string;
	productLine?: number;
}

export interface changeCartItemQuantityParamsType {
	cartId: number;
	itemId: number;
	quantity: number;
}

export interface removeItemParamType {
	cartId: number;
	itemId: number;
}

export interface batchRemoveCartItemsParamsType
	extends Pick<removeItemParamType, 'cartId'> {
	idList: object;
}

export interface previewParamsType {
	cartId: number;
	lineItemList: Partial<viewCartResItemType>[];
}

export interface quoteType {
	quoteNumber: string | number;
}

class ShoppingCartService {
	getCount = async () => {
		const response = await http.post<any, ResponseBaseType<number>>(
			'/cart/item/count',
			{}
		);
		return response;
	};
	getViewCart = async () => {
		const response = await http.post<any, ResponseBaseType<viewCartResType>>(
			'/cart/view',
			{}
		);
		return response;
	};
	reloadCartItemStockPrice = async (
		data: reloadCartItemStockPriceParamsType
	) => {
		const response = await http.post<
			reloadCartItemStockPriceParamsType,
			ResponseBaseType<null>
		>('/cart/reloadCartItemStockPrice', data);
		return response;
	};
	addItemToCart = async (data: addItemToCartParamsType) => {
		const response = await http.post<
			addItemToCartParamsType,
			ResponseBaseType<null>
		>('/cart/add/item', data);
		return response;
	};
	removeItemToCart = async (cartId: number, itemId: number) => {
		const response = await http.post<any, ResponseBaseType<null>>(
			`/cart/remove/${cartId}/item/${itemId}`,
			{}
		);
		return response;
	};
	changeCartItemQuantity = async (data: changeCartItemQuantityParamsType) => {
		const response = await http.post<
			changeCartItemQuantityParamsType,
			ResponseBaseType<null>
		>('/cart/changeItemQuantity', data);
		return response;
	};
	batchRemoveCartItems = async (
		cartId: number,
		data: batchRemoveCartItemsParamsType['idList']
	) => {
		const response = await http.post<
			batchRemoveCartItemsParamsType['idList'],
			ResponseBaseType<null>
		>(`/cart/remove/${cartId}/items`, { idList: data });
		return response;
	};
	preview = async (data: previewParamsType) => {
		const response = await http.post<previewParamsType, ResponseBaseType<any>>(
			'/checkout/preview',
			data
		);
		return response;
	};

	getQuote = async (data: any) => {
		const response = await http.get<quoteType, ResponseBaseType<any>>(
			`/sfdc/apply?quoteNumber=${data}`
		);
		return response;
	};
}

export const shoppingCartService = new ShoppingCartService();
