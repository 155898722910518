import { createSlice } from '@reduxjs/toolkit';

export interface FavoriteState {
	favoriteNum: number | string;
}

const initialState = {
	favoriteNum: 0,
} as FavoriteState;

const favorite = createSlice({
	name: 'favorite',
	initialState,
	reducers: {
		setFavoriteNum(state, action) {
			state.favoriteNum = action.payload;
		},
	},
});

export const { setFavoriteNum } = favorite.actions;
export default favorite.reducer;
