import React, { useRef,useEffect } from 'react';
import styled from '@emotion/styled';
import includes from 'lodash/includes';
import { VIDEO_TYPES, IMAGE_TYPES, EXCEL_TYPES,WORD_TYPES } from '@/core/utils';
import FileViewer  from 'react18-file-viewer';
import jsPreviewExcel, { JsExcelPreview } from '@js-preview/excel';
import '@js-preview/excel/lib/index.css';


const FileViewerBox = styled('div')`
	video {
    width: 100%;
  };
`;

export const ShowFile = ({ fileURL, fileType }: { fileURL: any, fileType: string}) => {
  const videoRef = useRef<any>(null);
  const excelContainerRef = useRef<HTMLDivElement | null>(null);
  const excelPreviewerRef = useRef<JsExcelPreview | null>(null);

  
  useEffect(() => {
    const containerElement = excelContainerRef?.current;

    if (containerElement && !excelPreviewerRef?.current) {
      // 初始化 myExcelPreviewer，并保存引用
      const myExcelPreviewer = jsPreviewExcel.init(containerElement);
      excelPreviewerRef.current = myExcelPreviewer;

      myExcelPreviewer
        .preview(fileURL)
        .then(() => {
          console.info('预览完成');
        })
        .catch((e) => {
          console.info('预览失败', e);
        });
    };

  }, [fileURL,fileType,excelContainerRef]);


  if(includes(VIDEO_TYPES, fileType)){
    return (
      <FileViewerBox>
        <video ref={videoRef} preload="auto" controls>
            <source src={fileURL} type={`video/${fileType}`} />
          </video>
      </FileViewerBox>
    );
  }else if(includes(IMAGE_TYPES, fileType)){
    return (
      <div>
        <img src={fileURL} className='w-full'/>
      </div>
    );
    }else if(fileType == 'pdf'){
      return (
        <div>
          <iframe src={`${fileURL}`} width='100%' height={'400px'} />
        </div>
      );
    }
    else if(includes(EXCEL_TYPES, fileType)){
      return (
        <div className='h-[400px] overscroll-y-auto' ref={excelContainerRef}>
        </div>
      );
    }else if(includes(WORD_TYPES, fileType)){
      return (
        <div className='h-[400px] overscroll-y-auto'>
          <FileViewer
          fileType={fileType}
          filePath={fileURL}/>
        </div>
      );
    }else{
      return <></>;
    };
};

