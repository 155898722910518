import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './App.scss';
import { AuthProvider } from './context/AuthContext';
import AppLayout from '@/layouts/AppLayout';
import AppLayoutJP from '@/layouts/AppLayoutJP';
import router from './router';
import map from 'lodash/map';
import size from 'lodash/size';
import { FormFloatButton, ScrollToTopButton } from '@/components';

const switchCountry = (region: string | number) => {
	switch (region) {
		case 'EN':
			return <AppLayout />;
		case 'CN':
			return <AppLayout />;
		case 'JP':
			return <AppLayoutJP />;
		default:
			return <AppLayoutJP />;
	}
};

const renderRoutes = (routes: any[]) => {
	return map(routes, (route) => {
		if (route.children && size(route.children) > 0) {
			// 如果有子路由，递归渲染子路由
			return (
				<Route key={route.path} path={route.path}>
					{renderRoutes(route.children)}
				</Route>
			);
		} else {
			// 如果没有子路由，直接渲染当前路由
			return (
				<Route key={route.path} path={route.path} element={route.element} />
			);
		}
	});
};

const AppRoutes = () => {
	const region = useSelector<any>((state) => state?.user?.region) as any;

	return (
		<>
			<Routes>
				{router()?.PublicRoutes.map((item) => (
					<Route key={item.path} path={item.path} element={item.element} />
				))}
				<Route path='/' element={switchCountry(region)}>
					{map(router()?.ApplayoutRoutes, (route: any) => (
						<Route key={route.path} path={route.path} element={route.element}>
							{/* 递归渲染子路由 */}
							{renderRoutes(route.children || [])}
						</Route>
					))}
				</Route>
			</Routes>
		</>
	);
};
function App() {
	return (
		<Router basename={process.env.REACT_APP_BASE_ROUTER_URL}>
			<ScrollToTopButton />
			<FormFloatButton />
			<AuthProvider>
				<AppRoutes />
			</AuthProvider>
		</Router>
	);
}

export default App;
