import { combineReducers } from 'redux';
import login from './login';
import search from './search';
import shoppingCart from './shoppingCart';
import user from './user';
import checkout from './checkout';
import myOrder from './myOrder';
import favorite from './favorite';
import userJp from './userJP';
import searchJp from './searchJp';
const rootReducer = combineReducers({
	login,
	search,
	shoppingCart,
	user,
	checkout,
	myOrder,
	favorite,
	userJp,
	searchJp,
});

export default rootReducer;
