import { useEffect, useState } from 'react';
import { Popup, Divider, Input } from 'antd-mobile';
import styled from '@emotion/styled';
import { t } from '@/core/hooks/useTranslation';
import { SearchOutline } from 'antd-mobile-icons';
import map from 'lodash/map';
import size from 'lodash/size';
import includes from 'lodash/includes';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';

type mobileSelect = {
	open: boolean;
	setOpen: (open: boolean) => void;
	searchValue: string;
	setSearchValue: (value: string) => void;
	list: any[];
	onOk: (id: number) => void;
	clickText: () => void;
	text: string;
};

const PopupBox = styled(Popup)`
	.adm-popup-body-position-bottom {
		border-top-left-radius: 0.5rem !important;
		border-top-right-radius: 0.5rem !important;
	}
`;

const SearchBox = styled('div')`
	width: 80%;
	background: #f7f7f7;
`;

export const PopupSelect = (props: mobileSelect) => {
	const [selectId, setSelectId] = useState<number | null>(null);
	const [filterList, setFilterList] = useState<any[]>([]);

	useEffect(() => {
		if (size(props.list) > 0) {
			setSelectId(props.list[0]?.id);
			setFilterList(props.list);
		}
	}, [props.list]);

	const inputSearch = (value: string) => {
		props.setSearchValue(value);
		const trimmedValue = trim(value);
		if (
			isEmpty(trimmedValue) ||
			trimmedValue === '' ||
			size(trimmedValue) <= 0
		) {
			setFilterList(props.list);
		} else {
			const filterList = filter(props.list, (item) => {
				return includes(`${item.companyName}${item.branchName}`, trimmedValue);
			});
			setFilterList(filterList);
		}
	};

	const onSearch = (cancel?: boolean) => {
		const filterList = filter(props.list, (item) => {
			return includes(
				`${item.companyName}${item.branchName}`,
				cancel ? '' : props.searchValue
			);
		});
		setFilterList(filterList);
	};

	const getId = (id: number) => {
		setSelectId(id);
	};

	const onOk = () => {
		props.setOpen(false);
		if (selectId) {
			props.onOk(selectId);
		}
	};

	return (
		<div>
			<PopupBox
				visible={props?.open}
				onMaskClick={() => props?.setOpen(false)}
				position='bottom'
			>
				<div className='min-h-[18rem]'>
					<div className='flex justify-between px-2 pt-[.7rem] text-[#0092F3] text-[0.938rem]'>
						<div
							className='cursor-pointer'
							onClick={() => {
								props?.setSearchValue('');
								onSearch(true);
								props?.setOpen(false);
							}}
						>
							{t('CommonJp.cancel')}
						</div>
						<div className='cursor-pointer' onClick={onOk}>
							{t('CommonJp.ok')}
						</div>
					</div>
					<Divider className='mt-[.7rem]' />
					<div className='flex justify-center'>
						<SearchBox className='py-[0.3rem] rounded-3xl px-3 flex justify-between items-center'>
							<Input
								onChange={inputSearch}
								className='w-[89%]'
								value={props.searchValue}
							/>
							<SearchOutline
								onClick={() => {
									onSearch(false);
								}}
								className='text-[1.3rem] text-[#A2A2A2]'
							/>
						</SearchBox>
					</div>
					{size(filterList) > 0 ? (
						<div className='w-full mt-3 max-h-[10rem] overflow-y-scroll pb-[3rem]'>
							{map(filterList, (item, index) => {
								return (
									<div
										key={index}
										onClick={() => getId(item?.id)}
										className={`flex items-center cursor-pointer w-full px-6 ${
											selectId == item?.id ? 'bg-[#E6F4FF]' : null
										} py-2 border border-[#EEEEEE] border-solid `}
									>
										<div
											className={`text-[0.875rem]  ${
												selectId == item?.id
													? 'text-[#1677FF]'
													: 'text-[#1B1B1D]'
											}`}
										>
											{item.companyName}
											<span>{`(${item.branchName})`}</span>
										</div>
									</div>
								);
							})}
						</div>
					) : null}
					<div
						onClick={props.clickText}
						className={`text-[#1677FF] text-[0.875rem] ${
							size(filterList) <= 0
								? 'border border-[#EEEEEE] border-solid mt-3'
								: 'fixed'
						} bottom-0 px-6 cursor-pointer w-full z-10 bg-white py-3`}
					>
						{props.text}
					</div>
				</div>
			</PopupBox>
		</div>
	);
};
