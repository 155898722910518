import { List as AntList } from 'antd';
import { ListProps, ListItemProps } from 'antd/lib/list';

const { Item: AntItem } = AntList;

export const List = (props: ListProps<any>) => {
	return <AntList {...props}>{props.children}</AntList>;
};

const Item = (props: ListItemProps) => {
	return <AntItem {...props}>{props.children}</AntItem>;
};

List.Item = Item;
