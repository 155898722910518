import { Upload as AntUpload, message } from 'antd';
import { UploadProps } from 'antd/lib/upload';
import icon from '@/assets/icons';
import { t } from '@/core/hooks/useTranslation';

const { Dragger } = AntUpload;

const defaultProps: UploadProps = {
	name: 'file',
	multiple: true,
	action: 'https://dev1-partner.thermofisher.com/api/v1/file/upload',
	onChange(info) {
		const { status } = info.file;
		console.log('uploadInfo:', info);
		if (status !== 'uploading') {
			console.log(info.file, info.fileList);
		}
		if (status === 'done') {
			message.success(`${info.file.name} file uploaded successfully.`);
		} else if (status === 'error') {
			message.error(`${info.file.name} file upload failed.`);
		}
	},
	onDrop(e) {
		console.log('Dropped files', e.dataTransfer.files);
	},
	beforeUpload(/*file, fileList*/) {
		return false;
	},
};

export const Upload = (props: UploadProps) => {
	return (
		<AntUpload {...defaultProps} {...props}>
			{props.children}
		</AntUpload>
	);
};

export const UploadDragger = (props: UploadProps) => {
	return (
		<Dragger {...defaultProps} {...props}>
			{props.children ? (
				props.children
			) : (
				<>
					<p className='ant-upload-drag-icon'>
						<img src={icon.upload_cloud_icon} alt='upload' />
					</p>
					<p className='ant-upload-text'>
						<span className='text-[#0071D0]'>
							{t('ContentManagementJp.dg_uploadSelectFile')}
						</span>{' '}
						{t('ContentManagementJp.dg_uploadSelectFileOther')}
					</p>
					<p className='ant-upload-hint'>
						{(props.accept ?? '').indexOf('.mp4') > -1
							? t('ContentManagementJp.dg_uploadSupports')
							: t('ContentManagementJp.dg_uploadSupportsNoVideo')}
					</p>
					<p className='ant-upload-hint'>
						{props.id === 'article'
							? t('ContentManagementJp.dg_upload_fileSize5')
							: t('ContentManagementJp.dg_upload_fileSize10')}
					</p>
				</>
			)}
		</Dragger>
	);
};

Upload.Dragger = UploadDragger;
