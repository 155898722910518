import { DownOutlined, UpOutlined, CloseOutlined } from '@ant-design/icons';
import { FC, useState } from 'react';
import { viewCartResItemComponentItemType } from '@/services/search.service';

export interface PackagedDropdownProps {
	data?: viewCartResItemComponentItemType[];
}

export const PackagedDropdownItem: FC<viewCartResItemComponentItemType> = ({
	quantity,
	sku,
	skuName,
}) => {
	return (
		<div className='mt-3'>
			<div className='text-xs font-semibold'>Catalog Number: {sku}</div>
			<div className='mt-1 flex justify-between text-gray-800 text-sm'>
				<div className='w-3/4 text-ellipsis whitespace-nowrap overflow-hidden font-semibold'>
					{skuName}
				</div>
				<div>
					<CloseOutlined className='text-xs' />
					<span className='ml-1'>{quantity}</span>
				</div>
			</div>
		</div>
	);
};

export const PackagedDropdown: FC<PackagedDropdownProps> = ({ data = [] }) => {
	const [isOpen, setOpen] = useState<boolean>(false);

	return (
		<div>
			<div
				className='flex justify-between mt-3 text-blue-400 text-xs'
				onClick={() => setOpen(!isOpen)}
			>
				<span>{`Packaged ${data.length} Components`}</span>
				{data.length > 1 && (isOpen ? <UpOutlined /> : <DownOutlined />)}
			</div>
			{isOpen ? (
				data.map((res) => <PackagedDropdownItem key={res.sku} {...res} />)
			) : (
				<>
					<PackagedDropdownItem {...data[0]} />
					{data.length > 1 && <span>...</span>}
				</>
			)}
		</div>
	);
};
