import { createSlice } from '@reduxjs/toolkit';

interface LoginState {
	params?: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	code: string | any;
}

const initialState = { params: 'login', code: '' } as LoginState;

const login = createSlice({
	name: 'login',
	initialState,
	reducers: {
		signIn(state, action) {
			state.code = action.payload.loginName;
		},
	},
});

export const { signIn } = login.actions;
export default login.reducer;
