import { createSlice } from '@reduxjs/toolkit';
import { user as userInfo } from '@/services/user.service';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import toLower from 'lodash/toLower';

const LANG = ['en', 'cn', 'jp'];

interface UserState {
	currentAccount?: string;
	lang?: string;
	region: string;
	userName: string;
	userBo: any;
	userPermissions: any;
}

function getLang(payload: any) {
	if (!isEmpty(payload) && includes(LANG, payload)) {
		return payload;
	};
	userInfo.setLanguage(payload);
}

const initialState = {
	currentAccount: '',
	lang: getLang(toLower(userInfo.getRegion() || 'jp')),
	region: '',
	userName: '',
	userBo: {},
	userPermissions: {},
} as UserState;

const user = createSlice({
	name: 'login',
	initialState,
	reducers: {
		setAccount(state, action) {
			state.currentAccount = action.payload;
		},
		setLang(state, action) {
			state.lang = getLang(action.payload);
		},
		setRegion(state, action) {
			state.region = action.payload;
		},
		setUserName(state, action) {
			state.userName = action.payload;
		},
		setUserBo(state, action) {
			state.userBo = action.payload;
		},
		setUserPermissions(state, action) {
			state.userPermissions = action.payload;
		},
	},
});

export const {
	setAccount,
	setLang,
	setRegion,
	setUserName,
	setUserBo,
	setUserPermissions,
} = user.actions;
export default user.reducer;
