import { Table as AntTable } from 'antd';
import { TableProps, ColumnProps } from 'antd/lib/table';
import { ColumnGroupProps } from 'antd/lib/table/ColumnGroup';
import { FC } from 'react';

const { Column: AntColumn, ColumnGroup: AntColumnGroup } = AntTable;

export const TableColumn: FC<ColumnProps<any>> = (props) => {
	return <AntColumn {...props}>{props.children}</AntColumn>;
};

export const TableColumnGroup: FC<ColumnGroupProps<any>> = (props) => {
	return <AntColumnGroup {...props}>{props.children}</AntColumnGroup>;
};

export const Table: FC<TableProps<any>> = (props) => {
	return <AntTable {...props}>{props.children}</AntTable>;
};
