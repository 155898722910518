import { FC, useCallback } from 'react';
import cn from 'classnames';
import Footer1 from '@/assets/images/Group 347.svg';
import Footer2 from '@/assets/images/Group 348.svg';
import Footer3 from '@/assets/images/Group 349.svg';
import Footer4 from '@/assets/images/Group 350.svg';
import Footer5 from '@/assets/images/Group 351.svg';
import Footer6 from '@/assets/images/Vector.svg';
import size from 'lodash/size';

export type NavOptions = {
	name?: string;
	imgUrl?: string;
	key?: string;
	href?: string;
};

export interface FooterProps {
	navOptions?: NavOptions[];
}

const defaultOptions: NavOptions[] = [
	{
		name: 'thermo scientific',
		key: 'thermoScientific',
		imgUrl: Footer1,
	},
	{
		name: 'applied bioSystems',
		key: 'appliedBioSystems',
		imgUrl: Footer2,
	},
	{
		name: 'invitrogen',
		key: 'invitrogen',
		imgUrl: Footer3,
	},
	{
		name: 'fisher scientific',
		key: 'fisherScientific',
		imgUrl: Footer4,
	},
	{
		name: 'unity labServices',
		key: 'unityLabServices',
		imgUrl: Footer5,
	},
	{
		name: 'PPD',
		key: 'PPD',
		imgUrl: Footer6,
	},
];

export const Footer: FC<FooterProps> = ({ navOptions = defaultOptions }) => {
	const navClick = useCallback<(res: NavOptions) => void>((res) => {
		if (!res.href) return;

		window.open(res.href);
	}, []);

	return (
		<div className=''>
			<div className='flex justify-center bg-primary'>
				<div className='flex justify-around items-end text-white h-14 overflow-hidden'>
					{navOptions &&
						size(navOptions) > 0 &&
						navOptions.map((res) => (
							<div
								className='leading-10 py-1 px-2 text-xl hover:border-0 hover:border-b-2 hover:border-solid hover:border-white cursor-pointer'
								key={res?.key}
								onClick={() => navClick(res)}
							>
								{res?.imgUrl ? (
									<img
										className={cn(
											res.key === 'thermoScientific'
												? 'h-5 mr-3 mb-1'
												: 'h-5 mr-3'
										)}
										src={res.imgUrl}
									/>
								) : (
									res?.name
								)}
							</div>
						))}
				</div>
			</div>
			<div className='flex'>
				<div className='w-content h-24 pt-8 box-content'>
					<span className='pl-20 text-slate-500'>
						Terms & Conditions | Privacy Policy | Price & Freight Policy
					</span>
				</div>
			</div>
		</div>
	);
};
