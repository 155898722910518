import { useQuery, useMutation } from '@tanstack/react-query';
import {
	searchService,
	suggestParamsType,
	searchParamsType,
} from '@/services/search.service';

export enum ServerStateKeysEnum {
	getSuggest = 'getSuggest',
	getSearch = 'getSearch',
	getSearchPrice = 'getSearchPrice',
}

export const useGetSuggest = (params: suggestParamsType) => {
	return useQuery([ServerStateKeysEnum.getSuggest], () =>
		searchService.getSuggest(params)
	);
};

export const useGetSearch = (params: searchParamsType) => {
	return useQuery([ServerStateKeysEnum.getSearch], () =>
		searchService.getSearch(params)
	);
};

export const useSearchPrice = () => {
	return useMutation({
		mutationKey: [ServerStateKeysEnum.getSearchPrice],
		mutationFn: (data: Array<any>) => searchService.getSearchPrice(data),
	});
};
