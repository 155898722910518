import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setLang } from '@/stores/reducers/user';
import logoRed from '@/assets/images/logo_red.svg';
import icon from '@/assets/icons';
import { Divider, ShowPDF } from '@/components';
import { t } from '@/core/hooks/useTranslation';
import { user } from '@/services/user.service';
import eventBus from '@/core/services/eventBus';
import { useCategoryList } from '@/hooks/userJp.hook';
import { setMenuData } from '@/stores/reducers/userJP';
import { ResponseBaseType } from '@/core/services/http.service';
import { useNavigate } from 'react-router-dom';

export const Footer = () => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const lang = useSelector<any>((state) => state?.user?.lang) as any;
	const dispatch = useDispatch();
	const [open, setOpen] = useState(false);
	const navigate = useNavigate();

	const { mutateAsync: useCategoryListMutate } = useCategoryList();

	const changeLang = (lang: string) => {
		eventBus.emit('changeLang', lang);
		dispatch(setLang(lang));
		user.setLanguage(lang);
		useCategoryListMutate({ categoryType: 4 }).then((response) => {
			const { code, data } = response as ResponseBaseType<any>;
			if (code === '000000') {
				dispatch(setMenuData(data));
			}
		});
	};

	const showModal = () => {
		setOpen(true);
	};

	const hideModal = () => {
		setOpen(false);
	};

	return (
		<div className='bg-white w-full px-3 py-4 flex justify-between'>
			<div className='flex items-center'>
				<img src={`${logoRed}`} />
				<div className='ml-6'>
					<div className='text-xs text-[#222]'>
						<a className='cursor-pointer text-[#222]' onClick={showModal}>
							{t('FooterJp.TermsConditions')}
						</a>
						<span className='px-[12px]'>|</span>
						<a
							className='text-[#222]'
							href='https://www.thermofisher.com/jp/ja/home/global/privacy-policy.html'
							target='__blank'
						>
							{t('FooterJp.PrivacyPolicy')}
						</a>
						<span className='px-[12px]'>|</span>
						<a
							className='text-[#222]'
							href='https://www.thermofisher.com/jp/ja/home/global/how-cookies-are-used.html'
							target='__blank'
						>
							{t('FooterJp.CookieNotice')}
						</a>
						<span className='px-[12px]'>|</span>
						<span
							className='text-[#222] cursor-pointer'
							onClick={() => {
								navigate('/contact-us');
							}}
						>
							{t('FooterJp.Inquiry')}
						</span>
					</div>
					<div className='text-xs mt-2'>
						© Copyright 2006-2024 Thermo Fisher Scientific Inc. All rights
						reserved.
					</div>
				</div>
			</div>
			<div className='flex items-center justify-center cursor-pointer text-xs mr-10'>
				<div className='flex flex-row justify-start items-center pr-[36px]'>
					<img src={`${icon?.jpLogo}`} />
					<span className='pl-[8px]'>{t('CommonJp.japan')}</span>
				</div>
				<div className='flex items-center justify-center ml-2'>
					<div
						onClick={() => {
							changeLang('jp');
						}}
					>
						日本語
					</div>
					<Divider type='vertical' className='bg-gray-300' />
					<div
						onClick={() => {
							changeLang('en');
						}}
					>
						English
					</div>
				</div>
			</div>
			<ShowPDF open={open} hideModal={hideModal} />
		</div>
	);
};
