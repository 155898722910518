import { useEffect, useState } from 'react';
import icon from '@/assets/icons';
import debounce from 'lodash/debounce';
import { useLocation } from 'react-router-dom';
import includes from 'lodash/includes';
import toUpper from 'lodash/toUpper';
import { removeScrollTopUrl } from '@/core/utils';
import map from 'lodash/map';

const ScrollToTop = () => {
	const { pathname } = useLocation();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return null;
};

const ToTopButton = () => {
	const [isBottom, setIsBottom] = useState('0');
	const clientHeight =
		document.documentElement.clientHeight || document.body.clientHeight;
	window.addEventListener(
		'scroll',
		debounce(function () {
			const scrollTop =
				document.documentElement.scrollTop || document.body.scrollTop;
			const scrollHeight =
				document.documentElement.scrollHeight || document.body.scrollHeight;
			if (scrollHeight > clientHeight) {
				setIsBottom('1');
			} else {
				setIsBottom('0');
			}

			if (scrollTop <= 100) {
				setIsBottom('0');
			}
		}, 1000)
	);

	return (
		<div
			className={`fixed right-[calc(120px/2)] bottom-[100px] ${
				isBottom === '1' ? 'bg-red-500' : 'bg-white'
			} w-[40px] h-[40px] rounded-[50%] flex justify-center items-center cursor-pointer z-30 custom-top-shadow`}
			onClick={() => {
				window.scrollTo(0, 0);
			}}
		>
			{isBottom === '1' ? (
				<img src={icon.arrow_up_icon} className='text-[24px] text-white' />
			) : (
				<img
					src={icon.arrow_up_icon_black}
					className='text-[32px] text-white'
				/>
			)}
		</div>
	);
};

export const ScrollToTopButton = () => {
	const [showIcon, setShowIcon] = useState<boolean>(true);
	useEffect(() => {
		if (
			includes(map(removeScrollTopUrl, toUpper), toUpper(location.pathname))
		) {
			setShowIcon(false);
		} else {
			setShowIcon(true);
		}
	}, [location.pathname]);

	return showIcon ? (
		<>
			<ScrollToTop />
			<ToTopButton />
		</>
	) : null;
};
