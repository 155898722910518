import { FC, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown, Badge, Menu } from '@/components';
import type { MenuProps } from 'antd';
import { useNavigate, To, Link } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Divider } from '@/components';
import { t } from '@/core/hooks/useTranslation';

export interface NavProps {
	accountItems?: MenuProps['items'];
	accountClick?: MenuProps['onClick'];
	navItems?: MenuProps['items'];
	navItemOnClick?: MenuProps['onClick'];
	catNum?: number;
	selectedKeys?: Array<string>;
	setAutoVal: (value: any) => void;
	clickIcon: MenuProps['onClick'];
	tokenType: string;
	checkoutToken?: MenuProps['items'];
	changeLang?: () => void;
}

const StyleMenu = styled(Menu)`
	border: none;
	.ant-menu-item-selected {
		color: #000 !important;
		font-weight: 600 !important;
	}
`;

const defaultItems: MenuProps['items'] = [
	{
		label: 'default',
		key: 'default',
	},
];

const Nav: FC<NavProps> = ({
	accountItems,
	accountClick,
	navItems,
	navItemOnClick,
	catNum,
	selectedKeys,
	setAutoVal,
	clickIcon,
	tokenType,
	checkoutToken,
	changeLang,
}) => {
	const defaultSelectedKeys = useMemo<MenuProps['defaultSelectedKeys']>(() => {
		if (!navItems || !navItems.length) return undefined;

		if (typeof navItems[0]?.key !== 'string') return undefined;

		return [navItems[0]?.key];
	}, [navItems]);

	const navigate = useNavigate();

	const goPage = useCallback<(path: To) => void>((path) => {
		navigate(path);
	}, []);

	const lang = useSelector<any>((state) => state?.user?.lang) as any;

	return (
		<div className='flex pt-6 pb-4 justify-between w-full'>
			<div className='flex'>
				<Link to='/'>
					<div className='bg-logo w-44 h-8 bg-contain bg-no-repeat border-0 border-r-2 border-gray-200 border-solid pr-6' />
				</Link>
				<div className='h-8 text-2xl text-neutral-800 pl-4 whitespace-nowrap'>
					Partner Portal
				</div>
			</div>
			<div className='w-1/2 -mt-2'>
				<StyleMenu
					mode='horizontal'
					items={navItems}
					onClick={navItemOnClick}
					defaultSelectedKeys={defaultSelectedKeys}
					selectedKeys={selectedKeys}
					className='justify-center'
				/>
			</div>
			<div className='flex h-8 py-1'>
				<div className='mr-5 cursor-pointer whitespace-nowrap'>
					<Dropdown
						menu={{
							items: accountItems || defaultItems,
							onClick: accountClick,
						}}
					>
						<span>
							{t('home.MyAccount')}
							<DownOutlined className='ml-2' />
						</span>
					</Dropdown>
				</div>
				<Badge count={catNum || 0} className='mr-6' size='small'>
					<div
						className='icon-24-cart-default-bold w-6 h-6 cursor-pointer'
						onClick={() => {
							goPage('/shopping-cart');
							setAutoVal('');
						}}
					/>
				</Badge>
				{/* <div className='cursor-pointer flex'>
					<img src={icon.singaporeIcon} className='mr-3' />
					<span className='text-sm'>Singapore</span>
				</div> */}
				<div className='ml-2 cursor-pointer'>
					<Dropdown
						menu={{
							items: checkoutToken,
							onClick: clickIcon,
						}}
					>
						<div className='text-sm flex'>
							{tokenType}
							<DownOutlined className='ml-1' />
						</div>
					</Dropdown>
				</div>
				<Divider className='bg-gray-400 h-6 mx-3' type='vertical' />
				<div onClick={changeLang} className='font-extrabold cursor-pointer'>
					{lang == 'en' ? 'EN' : 'CN'}
				</div>
			</div>
		</div>
	);
};

export default Nav;
