import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider } from '@/components';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { rootStore, persistor } from './stores';
import includes from 'lodash/includes';
import { myLoginService } from '@/services/login.service';

import './assets/css/komodo-icons.css';
import './index.css';

import cn from '@/assets/lang/cn.json';
import en from '@/assets/lang/en.json';
import jp from '@/assets/lang/jp.json';
import {
	setDefaultLanguage,
	setTranslations,
} from './core/hooks/useTranslation';

const queryParams = new URLSearchParams(window.location.search);
const email = queryParams.get('email');
if (includes(window.location.pathname, 'notification') && email) {
	myLoginService.setForwardDirect(
		`${window.location.pathname}${window.location.search}`
	);
	myLoginService.setNotificationEmailMark(
		`${window.location.pathname}${window.location.search}`
	);
}

const language = rootStore.getState()?.user?.lang;

// set up the default language
setTranslations({ cn, en, jp });
setDefaultLanguage(language || 'jp');

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: 1000 * 10,
			...(process.env.REACT_APP_QUERY_DEV_MODE === 'true' && {
				refetchOnWindowFocus: false,
			}),
		},
	},
});

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(
	<React.StrictMode>
		<Provider store={rootStore}>
			<PersistGate persistor={persistor}>
				<QueryClientProvider client={queryClient}>
					<ConfigProvider>
						<App />
					</ConfigProvider>
					<ReactQueryDevtools position='bottom-right' />
				</QueryClientProvider>
			</PersistGate>
		</Provider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
