import React, { createContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { setLanguage } from '@/core/hooks/useTranslation';
// import { checkExpToken, TIME_OUT } from '@/core/utils';
interface IAuthContext {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	authState?: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	setAuthState?: (state: any) => void;
	logout?: () => void;
	isAuthenticated: () => boolean;
	isAdmin: () => boolean;
}

const AuthContext = createContext<IAuthContext>({
	isAuthenticated: () => false,
	isAdmin: () => false,
});

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
	const navigate = useNavigate();

	const token = localStorage.getItem('token');
	const userInfo = localStorage.getItem('userInfo');
	const expiresAt = localStorage.getItem('expiresAt');

	const [authState, setAuthState] = useState<{
		token: null | string;
		expiresAt: null | string;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		userInfo: any;
	}>({
		token,
		expiresAt,
		userInfo: userInfo ? JSON.parse(userInfo) : {},
	});

	const lang = useSelector<any>((state) => state?.user?.lang) as any;
	setLanguage(lang);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const setAuthInfo = ({ token, userInfo, expiresAt }: any) => {
		localStorage.setItem('token', token);
		localStorage.setItem('userInfo', JSON.stringify(userInfo));
		localStorage.setItem('expiresAt', expiresAt);

		setAuthState({
			token,
			userInfo,
			expiresAt,
		});
	};

	const logout = () => {
		localStorage.removeItem('token');
		localStorage.removeItem('userInfo');
		localStorage.removeItem('expiresAt');
		setAuthState({ token: null, userInfo: null, expiresAt: null });
		navigate('/');
	};

	const isAuthenticated = (): boolean => {
		return true;
	};

	const isAdmin = (): boolean => {
		return authState.userInfo.role === 'admin';
	};

	return (
		<AuthContext.Provider
			value={{
				authState,
				setAuthState: (authInfo) => setAuthInfo(authInfo),
				logout,
				isAuthenticated,
				isAdmin,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export { AuthContext, AuthProvider };
