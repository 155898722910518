/*
 * @Author: yang.jinpeng
 * @Date: 2023-11-16 15:16:15
 * @Last Modified time: 2023-11-16 17:33:57
 */

import { Menu } from '@/components/Menu';
import type { MenuProps } from 'antd';
import styled from '@emotion/styled';
import { t } from '@/core/hooks/useTranslation';
import { useSelector } from 'react-redux';
import size from 'lodash/size';

export type MenuNextProps = {
	items: MenuProps['items'];
	defaultOpenKeys?: string[];
	openKeys?: string[];
	defaultSelectedKeys?: string[];
	selectedKeys?: string[];
	mode?: 'inline';
	inlineIndent?: number;
	tooltipTitle?: string;
	style?: React.CSSProperties;
	onClick?: (e: any) => void;
	onOpenChange?: (e: any) => void;
};

export const MenuNext = (props: MenuNextProps) => {
	const lang = useSelector<any>((state) => state?.user?.lang) as any;

	const {
		items = [],
		defaultOpenKeys,
		openKeys,
		defaultSelectedKeys,
		selectedKeys,
		mode = 'inline',
		inlineIndent = 20,
		tooltipTitle = t('CommonJp.tooltipTitle'),
		style = {},
		onClick = () => {
			console.warn(' Is Parent Component Adds An Operation ?');
		},
		onOpenChange = () => {
			console.warn(' Is Parent Component Adds An Operation ?');
		},
	} = props;

	return (
		<BoxMain className='w-[232px] mr-[24px] py-[18px] bg-white'>
			<Menu
				defaultSelectedKeys={defaultSelectedKeys}
				defaultOpenKeys={defaultOpenKeys}
				openKeys={openKeys}
				selectedKeys={selectedKeys}
				className='relative'
				id='container'
				items={
					items &&
					items.map((item: any) => {
						return {
							...item,
							icon: (
								<ImgBox>
									<img src={item?.icon} className='w-[1.2rem]' />
								</ImgBox>
							),
							label: (
								<span className='w-[calc(100%-30px)]'>
									{item?.isunread === '1' ? (
										<span>
											<span className='flex flex-row justify-start items-center'>
												<span
													className='block w-auto truncate'
													title={item?.label}
												>
													{item?.label}
												</span>
												{item?.labelicon ? (
													<img className='ml-2' src={item?.labelicon} />
												) : (
													<sup
														className='text-red-500 text-2xl pl-1'
														title={tooltipTitle}
													>
														•
													</sup>
												)}
											</span>
										</span>
									) : size(item?.label) >= (lang == 'jp' ? 10 : 20) ? (
										<span>
											<span
												className='block w-full truncate'
												title={item?.label}
											>
												{item?.label}
											</span>
										</span>
									) : (
										<span className='block truncate' title={item?.label}>
											{item?.label}
										</span>
									)}
								</span>
							),
							children: item?.children?.map((j: any) => {
								return {
									...j,
									icon: <div className='pl-6'></div>,
									label: (
										<span className='w-[calc(100%-30px)]'>
											{j.isunread === '1' ? (
												<span>
													<span className='flex flex-row justify-start items-center'>
														<span
															title={j?.label}
															className='break-words block overflow-ellipsis overflow-hidden whitespace-nowrap'
														>
															{j?.label}
														</span>
														{j?.labelicon ? (
															<img className='ml-2' src={j?.labelicon} />
														) : (
															<sup className='text-red-500 text-2xl pl-1'>
																•
															</sup>
														)}
													</span>
												</span>
											) : j?.label && j?.label.length >= 20 ? (
												<span title={j?.label}>
													<span className='break-words block overflow-ellipsis overflow-hidden whitespace-nowrap'>
														{j?.label}
													</span>
												</span>
											) : (
												<span className='break-words block overflow-ellipsis overflow-hidden whitespace-nowrap'>
													{j?.label}
												</span>
											)}
										</span>
									),
								};
							}),
						};
					})
				}
				mode={mode}
				inlineIndent={inlineIndent}
				style={style}
				onClick={onClick}
				onOpenChange={onOpenChange}
			/>
		</BoxMain>
	);
};

const BoxMain = styled.div`
	.ant-menu-light.ant-menu-inline .ant-menu-item,
	.ant-menu-light.ant-menu-inline .ant-menu-submenu-title {
		width: calc(100% - 4px);
	}

	.ant-menu-light .ant-menu-item-selected,
	.ant-menu-light > .ant-menu .ant-menu-item-selected {
		font-weight: 600;
	}

	.ant-menu-light.ant-menu-inline .ant-menu-selected::after,
	.ant-menu-light.ant-menu-inline .ant-menu-item-selected::after {
		border-right-color: red;
		width: 6px;
		background-color: white;
	}

	.ant-menu-inline.ant-menu-root .ant-menu-item > *,
	.ant-menu-inline.ant-menu-root .ant-menu-submenu-title > * {
		margin-left: 0;
	}
	.ant-menu-item ant-menu-item-only-child {
		padding-left: 80px;
	}
	.ant-menu-light,
	.ant-menu-root,
	.ant-menu-inline {
		border-inline-end: none !important;
	}
`;

const ImgBox = styled.div`
	width: 28px;
	height: 16px;
`;
