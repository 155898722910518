import { Input as AntInput, InputRef } from 'antd';
import { InputProps, TextAreaProps, SearchProps } from 'antd/lib/input';
import { forwardRef } from 'react';

const { TextArea: AntTextArea, Search: AntSearch } = AntInput;

export const Input = forwardRef<InputRef, InputProps>((props, ref) => {
	return (
		<AntInput {...props} ref={ref}>
			{props.children}
		</AntInput>
	);
});

export const TextArea = forwardRef<InputRef, TextAreaProps>((props, ref) => {
	return (
		<AntTextArea {...props} ref={ref}>
			{props.children}
		</AntTextArea>
	);
});

export const Search = forwardRef<InputRef, SearchProps>((props, ref) => {
	return (
		<AntSearch {...props} ref={ref}>
			{props.children}
		</AntSearch>
	);
});
