import { Menu as AntMenu } from 'antd';
import {
	MenuProps,
	SubMenuProps,
	MenuItemProps,
	MenuItemGroupProps,
	MenuDividerProps,
} from 'antd/lib/menu';

const {
	SubMenu: AntSubMenu,
	Item: AntItem,
	ItemGroup: AntItemGroup,
	Divider: AntDivider,
} = AntMenu;

export const Menu = (props: MenuProps) => {
	return <AntMenu {...props}>{props.children}</AntMenu>;
};

const SubMenu = (props: SubMenuProps) => {
	return <AntSubMenu {...props}>{props.children}</AntSubMenu>;
};

const Item = (props: MenuItemProps) => {
	return <AntItem {...props}>{props.children}</AntItem>;
};

const ItemGroup = (props: MenuItemGroupProps) => {
	return <AntItemGroup {...props}>{props.children}</AntItemGroup>;
};

const Divider = (props: MenuDividerProps) => {
	return <AntDivider {...props}>{props.children}</AntDivider>;
};

Menu.SubMenu = SubMenu;
Menu.Item = Item;
Menu.ItemGroup = ItemGroup;
Menu.Divider = Divider;
