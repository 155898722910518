import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import logger from 'redux-logger';

import rootReducer from './reducers';

const persistConfig = {
	key: 'root',
	storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const middlewareHandler = (getDefaultMiddleware: any) => {
	const middlewareList = [
		...getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: ['persist/PERSIST'],
			},
		}),
	];
	if (process.env.NODE_ENV === 'development') {
		middlewareList.push(logger);
	}
	return middlewareList;
};

export const rootStore = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) => middlewareHandler(getDefaultMiddleware),
});

export const persistor = persistStore(rootStore);
export type RootState = ReturnType<typeof rootStore.getState>;
