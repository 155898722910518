import { createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';

export interface myOrderSearchType {
	orderNumber?: string;
}

export interface SearchState {
	searchHistory: myOrderSearchType[];
	searchKey: string;
	paginationData: object;
	orderNumberFlag: string;
	orderDateFlag: string;
}

const initialState = {
	searchHistory: [],
	searchKey: '',
	paginationData: { count: '', pageNo: 1, pageSize: 10 },
	orderNumberFlag: 'desc',
	orderDateFlag: 'desc',
} as SearchState;

const myOrder = createSlice({
	name: 'myOrder',
	initialState,
	reducers: {
		setSearchHistory(state, action) {
			const result = state?.searchHistory.find((res) => {
				return res.orderNumber === action.payload.orderNumber;
			});

			if (result) return;
			if (
				isEmpty(action.payload) ||
				includes(state?.searchHistory, action.payload)
			) {
				return;
			} else {
				state?.searchHistory.unshift(action.payload);
			}

			if (state?.searchHistory.length > 10) {
				state.searchHistory.length = 10;
			}
		},

		setSearchKey(state, action) {
			state.searchKey = action.payload;
		},

		setPagination(state, action) {
			state.paginationData = action.payload;
		},
		setOrderNumberFlag(state, action) {
			state.orderNumberFlag = action.payload;
		},
		setOrderDateFlag(state, action) {
			state.orderDateFlag = action.payload;
		},
	},
});

export const {
	setSearchHistory,
	setSearchKey,
	setPagination,
	setOrderNumberFlag,
	setOrderDateFlag,
} = myOrder.actions;
export default myOrder.reducer;
