import { ConfigProvider as AntdConfigProvider } from 'antd';

type Props = {
	children: JSX.Element;
};

export const ConfigProvider = ({ children }: Props) => (
	<AntdConfigProvider
		theme={{
			token: {
				colorPrimary: '#e71316',
				fontFamily: '"HelveticaNeue", sans-serif',
				borderRadius: 2,
				colorPrimaryBg: '#e6f4ff',
			},
			components: {
				Menu: {
					itemMarginInline: 4,
					subMenuItemBg: '#FFF',
					activeBarWidth: 2,
					itemActiveBg: '#F7F7F7',
					itemSelectedColor: '#1B1B1D',
					itemHoverBg: '#F7F7F7',
					itemSelectedBg: '#F7F7F7',
				},
				Breadcrumb: {
					itemColor: '#FFF',
					linkColor: '#FFF',
					linkHoverColor: '#FFF',
					separatorColor: '#FFF',
					lastItemColor: '#FFF',
				},
				Tabs: {
					itemColor: '#54545C',
					itemHoverColor: '#54545C',
					inkBarColor: '#E71316',
					itemSelectedColor: '#1B1B1D',
				},
				Checkbox: {
					colorPrimary: '#1677FF',
					colorPrimaryHover: '#1677FF',
				},
				Button: {
					dangerColor: '#e71316',
				},
			},
		}}
	>
		{children}
	</AntdConfigProvider>
);
