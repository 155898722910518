
import {
	CloseCircleOutlined,
	LoadingOutlined,
} from '@ant-design/icons';
import { t } from '@/core/hooks/useTranslation';


export type FileMaskProps = {
	close?: (e: any) => void;
};

export const FileMask = (props: FileMaskProps) => {
	return (
        <div className='w-full h-[100%] bg-black opacity-60 fixed top-0 left-0 z-[99999] flex flex-col items-center'>
        <CloseCircleOutlined
            className='text-white text-[1.5rem] mt-[2.5rem] mr-[6rem] self-end cursor-pointer opacity-100'
            onClick={props?.close}
        />
        <div className='w-6'>
            <LoadingOutlined className='text-white text-[2rem] mt-10 opacity-100' />
        </div>
        <div className='text-white mt-10 opacity-100 text-center'>{t('HelpJp.loadingText')}</div>
    </div>
    );
};
