import { useQuery, useMutation } from '@tanstack/react-query';
import {
	shoppingCartService,
	reloadCartItemStockPriceParamsType,
	addItemToCartParamsType,
	changeCartItemQuantityParamsType,
	batchRemoveCartItemsParamsType,
	previewParamsType,
	removeItemParamType,
} from '@/services/shoppingCart.service';

export enum ShoppingCartServerStateKeysEnum {
	getCount = 'getCount',
	getViewCart = 'getViewCart',
	reloadCartItemStockPrice = 'reloadCartItemStockPrice',
	addItemToCart = 'addItemToCart',
	removeItemToCart = 'removeItemToCart',
	changeCartItemQuantity = 'changeCartItemQuantity',
	batchRemoveCartItems = 'batchRemoveCartItems',
	preview = 'preview',
}

export const useGetCount = () => {
	return useQuery([ShoppingCartServerStateKeysEnum.getCount], () =>
		shoppingCartService.getCount()
	);
};

export const useGetViewCart = () => {
	return useQuery([ShoppingCartServerStateKeysEnum.getViewCart], () =>
		shoppingCartService.getViewCart()
	);
};

export const useReloadCartItemStockPrice = () => {
	return useMutation({
		mutationKey: [ShoppingCartServerStateKeysEnum.reloadCartItemStockPrice],
		mutationFn: (data: reloadCartItemStockPriceParamsType) =>
			shoppingCartService.reloadCartItemStockPrice(data),
	});
};

export const useAddItemToCart = () => {
	return useMutation({
		mutationKey: [ShoppingCartServerStateKeysEnum.addItemToCart],
		mutationFn: (data: addItemToCartParamsType) =>
			shoppingCartService.addItemToCart(data),
	});
};

export const useRemoveItemToCart = () => {
	return useMutation({
		mutationKey: [ShoppingCartServerStateKeysEnum.changeCartItemQuantity],
		mutationFn: (data: removeItemParamType) =>
			shoppingCartService.removeItemToCart(data.cartId, data.itemId),
	});
};

export const useChangeCartItemQuantity = () => {
	return useMutation({
		mutationKey: [ShoppingCartServerStateKeysEnum.changeCartItemQuantity],
		mutationFn: (data: changeCartItemQuantityParamsType) =>
			shoppingCartService.changeCartItemQuantity(data),
	});
};

export const useBatchRemoveCartItems = () => {
	return useMutation({
		mutationKey: [ShoppingCartServerStateKeysEnum.batchRemoveCartItems],
		mutationFn: (data: batchRemoveCartItemsParamsType) =>
			shoppingCartService.batchRemoveCartItems(data.cartId, data.idList),
	});
};

export const usePreview = () => {
	return useMutation({
		mutationKey: [ShoppingCartServerStateKeysEnum.preview],
		mutationFn: (data: previewParamsType) => shoppingCartService.preview(data),
	});
};
