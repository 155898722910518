import { FC, useState, useCallback, useEffect } from 'react';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { InputNumberProps } from 'antd/lib/input-number';
import { InputNumber } from '../';
import styled from '@emotion/styled';

const StyleInputNumber = styled(InputNumber)`
	.ant-input-number-input-wrap input {
		padding: 0 0 !important;
		text-align: center !important;
	}
	.ant-input-number-input-wrap {
		width: 2rem !important;
	}
`;

export interface QuantityInputProps {
	number: number;
	onChangeNum?: (num: number, type: string) => void;
	disable?: boolean;
}

export const QuantityInput: FC<QuantityInputProps> = ({
	number: numberProps,
	onChangeNum,
	disable = false,
}) => {
	const [resultNum, setResultNum] = useState<number>(numberProps || 1);
	const [inputVal, setInputVal] = useState<number>(1);

	const numChange = useCallback<
		Exclude<InputNumberProps['onChange'], undefined>
	>(
		(val) => {
			if (disable) return;
			if (val === null) return;

			setInputVal(typeof val === 'number' ? val : +val);
		},
		[disable]
	);

	const numBlur = useCallback(() => {
		if (inputVal <= 0) {
			setResultNum(1);
			setInputVal(1);
			onChangeNum && onChangeNum(1, 'add');
			return;
		}

		setResultNum(+inputVal);
		onChangeNum && onChangeNum(+inputVal, 'add');
	}, [inputVal, onChangeNum]);

	const minusNum = useCallback(() => {
		if (resultNum <= 1 || disable) return;

		setResultNum(resultNum - 1);
		setInputVal(resultNum - 1);
		onChangeNum && onChangeNum(resultNum - 1, 'minus');
	}, [resultNum, disable, onChangeNum]);

	const addNum = useCallback(() => {
		if (disable) return;

		setResultNum(resultNum + 1);
		setInputVal(resultNum + 1);
		onChangeNum && onChangeNum(resultNum + 1, 'add');
	}, [resultNum, disable, onChangeNum]);

	useEffect(() => {
		setResultNum(+numberProps);
		setInputVal(+numberProps);
	}, [numberProps]);

	return (
		<div className='flex border border-solid border-gray-300 w-max'>
			<div
				className='w-8 h-8 text-center leading-8 cursor-pointer'
				onClick={minusNum}
			>
				<MinusOutlined />
			</div>
			<div className='w-8 h-8 border-0 border-l border-r border-solid border-gray-300 overflow-hidden'>
				<StyleInputNumber
					width='100%'
					value={inputVal}
					onChange={numChange}
					onBlur={numBlur}
					controls={false}
					bordered={false}
				/>
			</div>
			<div
				className='w-8 h-8 text-center leading-8 cursor-pointer'
				onClick={addNum}
			>
				<PlusOutlined />
			</div>
		</div>
	);
};
