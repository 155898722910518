import styled from '@emotion/styled';

export const TableDiv = styled('div')`
	.ant-table-wrapper .ant-table-thead > tr > th {
		padding: 8px 16px;
		background: #f7f7f7;
	}
	.ant-table-wrapper
		.ant-table-thead
		> tr
		> th:not(:last-child):not(.ant-table-selection-column):not(
			.ant-table-row-expand-icon-cell
		):not([colspan])::before {
		display: none;
	}
	.ant-table-wrapper .ant-table-tbody > tr > td {
		padding-bottom: 34px;
	}
	.ant-table-wrapper
		.ant-table-container
		table
		> thead
		> tr:first-of-type
		th:last-child {
		padding-right: 32px;
	}
	.ant-table-wrapper table tr td.ant-table-selection-column {
		vertical-align: top;
	}
	.ant-table-wrapper .ant-table-tbody > tr.ant-table-row-selected:hover > td {
		background: #ededed;
	}
	.ant-table-wrapper .ant-table-tbody > tr.ant-table-row-selected > td {
		background: #fafafa;
	}
`;
