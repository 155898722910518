import React, { FC } from 'react';

export interface PackagedTagProps {
	content?: React.ReactNode;
}

export const PackagedTag: FC<PackagedTagProps> = ({ content = 'Packaged' }) => {
	return (
		<span className='text-xs px-2 py-1 text-blue-400 bg-blue-100 rounded-sm'>
			{content}
		</span>
	);
};

export const DiscontinuedTag: FC<PackagedTagProps> = ({
	content = 'Discontinued',
}) => {
	return (
		<span className='text-xs px-2 py-1 text-white bg-gray-400 rounded-sm'>
			{content}
		</span>
	);
};
