import { http, ResponseBaseType } from '@/core/services/http.service';

export interface authoritiesType {
	authority: string;
}

export interface shipToAddressType {
	address1: string;
	address2: string;
	addressId: string;
	city: string;
	cityCode: string;
	contactNumber: string;
	country: string;
	email: string;
	id: number;
	postalcode: string;
	soldToId: string;
	type: string;
	currency: string;
}

export interface CurrentUserInfoType {
	userDetails: {
		accountNonExpired: boolean;
		accountNonLocked: boolean;
		authorities: authoritiesType[];
		billToId: string;
		credentialsNonExpired: boolean;
		enabled: boolean;
		password: string;
		shipToAddress: shipToAddressType[];
		shipToIds: string[];
		username: string;
		soldToAddress: shipToAddressType;
		legalEntity: number;
		country: string;
		userBO?: any;
	};
}

class User {
	getCurrentUserInfo = async () => {
		const response = await http.get<any, ResponseBaseType<CurrentUserInfoType>>(
			'/auth/currentUser'
		);
		return response;
	};

	getSiteSelector = async () => {
		const response = await http.get<any, ResponseBaseType<CurrentUserInfoType>>(
			'/form/getJapanSiteSelector'
		);
		return response;
	};

	private region = 'region';

	getRegion = () => {
		return localStorage.getItem(this.region);
	};

	setRegion = (item: string) => {
		return localStorage.setItem(this.region, item);
	};

	private language = 'language';

	getLanguage = () => {
		return localStorage.getItem(this.language);
	};

	setLanguage = (item: any) => {
		return localStorage.setItem(this.language, item);
	};

	private userBo = 'userBo';

	getUserBo = () => {
		return localStorage.getItem(this.userBo);
	};

	setUserBo = (item: string) => {
		return localStorage.setItem(this.userBo, item);
	};

	setUserPermissions = (item: string) => {
		return localStorage.setItem('userPermissions', item);
	};

	private usercode = 'usercode';

	getUsercode = () => {
		return localStorage.getItem(this.usercode);
	};

	setUsercode = (item: string) => {
		return localStorage.setItem(this.usercode, item);
	};

	removeUsercode = () => {
		return localStorage.removeItem(this.usercode);
	};
}

export const user = new User();
