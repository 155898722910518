import { useQuery, useMutation } from '@tanstack/react-query';
import {
	getuser,
	newsUnreadType,
	setReadType,
	setFavouriteType,
	downloadFileType,
} from '@/services/userJp.service';

export enum CheckoutUserType {
	nav = 'nav',
	getNav = 'getNav',
	newsUnread = 'newsUnread',
	setRead = 'setRead',
	setFavourite = 'setFavourite',
	downloadFile = 'downloadFile',
}

export const useCategoryList = () => {
	return useMutation({
		mutationKey: [CheckoutUserType.nav],
		mutationFn: (data?: any) => getuser.categoryList(data),
	});
};

export const useGetCategoryList = (data?: any) => {
	return useQuery([CheckoutUserType.getNav], () => getuser.categoryList(data));
};

export const useGetNewsUnread = () => {
	return useMutation({
		mutationKey: [CheckoutUserType.nav],
		mutationFn: (data: newsUnreadType) => getuser.getNewsUnread(data),
	});
};

export const useSetRead = () => {
	return useMutation({
		mutationKey: [CheckoutUserType.setRead],
		mutationFn: (data: setReadType) => getuser.setRead(data),
	});
};

export const useSetFavourite = () => {
	return useMutation({
		mutationKey: [CheckoutUserType.setFavourite],
		mutationFn: (data: setFavouriteType) => getuser.setFavourite(data),
	});
};

export const useGetDownloadFile = () => {
	return useMutation({
		mutationKey: [CheckoutUserType.downloadFile],
		mutationFn: (data: downloadFileType) => getuser.getDownloadFile(data),
	});
};
