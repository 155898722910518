import { Radio as AntRadio } from 'antd';
import { RadioProps, RadioGroupProps } from 'antd/lib/radio';

const { Group: AntGroup, Button: AntButton } = AntRadio;

export const Radio = (props: RadioProps) => {
	return <AntRadio {...props}>{props.children}</AntRadio>;
};

const Group = (props: RadioGroupProps) => {
	return <AntGroup {...props}>{props.children}</AntGroup>;
};

const Button = (props: any) => {
	return <AntButton {...props}>{props.children}</AntButton>;
};

Radio.Group = Group;
Radio.Button = Button;
