import { Cascader as AntCascader } from 'antd';
import { CascaderProps } from 'antd/lib/cascader';
import { FC } from 'react';

export const Cascader: any = (props: any) => {
	return <AntCascader {...props}>{props.children}</AntCascader>;
};

export const CascaderPanel: FC<CascaderProps> = (props) => {
	return <AntCascader.Panel {...props}>{props.children}</AntCascader.Panel>;
};

Cascader.Panel = AntCascader.Panel;
