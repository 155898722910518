import { FC, useCallback, useEffect, useState } from 'react';
import { SelectProps, DefaultOptionType } from 'antd/lib/select';
import { AutoCompleteProps } from 'antd/lib/auto-complete';
import { Button, AutoComplete, Select, Input, Modal } from '@/components';
import styled from '@emotion/styled';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { t } from '@/core/hooks/useTranslation';
import { setSearchType } from '@/stores/reducers/searchJp';
import trim from 'lodash/trim';
import size from 'lodash/size';
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import { ExclamationCircleFilled } from '@ant-design/icons';

type selectOptionsType = SelectProps['options'];

export interface SearchPropsJP {
	selectOptions?: selectOptionsType;
	placeholder?: string;
	onSubmit?: (
		value: DefaultOptionType['value'],
		selectVal: DefaultOptionType['value']
	) => void;
	autoCompleteOptions?: AutoCompleteProps['options'];
	autoCompleteDefaultOptions?: AutoCompleteProps['options'];
	autoCompleteOnchange?: AutoCompleteProps['onChange'];
	isClearSearchVal?: boolean;
	clearValCallback?: () => void;
	setAutoVal: (value: any) => void;
	autoVal: any;
}

const StyleSelect = styled(Select)`
	width: 263px;
	.ant-select-selector {
		background: #f5f5f5 !important;
		border-right: none !important;
	}
	,
	.ant-select-selector {
		border: 1px solid ##d9d9d9 !important;
	}
	.ant-select-selector:hover {
		border-color: #ccc !important;
	}
`;

const enSelectData = [
	{
		label: <div className='text-center'>Search All</div>,
		value: 'all',
	},
	{
		label: <div className='text-center'>Notifications</div>,
		value: 'Notifications',
	},
	{
		label: <div className='text-center'>Products</div>,
		value: 'Products',
	},
	{
		label: <div className='text-center'>Services</div>,
		value: 'Services',
	},
	{
		label: <div className='text-center'>Help</div>,
		value: 'Help',
	},
];

const jpSelectData = [
	{
		label: <div className='text-center'>すべて検索</div>,
		value: 'all',
	},
	{
		label: <div className='text-center'>お知らせ</div>,
		value: 'お知らせ',
	},
	{
		label: <div className='text-center'>製品</div>,
		value: '製品',
	},
	{
		label: <div className='text-center'>サービス</div>,
		value: 'サービス',
	},
	{
		label: <div className='text-center'>ヘルプ</div>,
		value: 'ヘルプ',
	},
];

const findMatchingValue = (str: string) => {
	const enIndex = findIndex(enSelectData, { value: str });
	const jpIndex = findIndex(jpSelectData, { value: str });

	if (enIndex !== -1) {
		return get(jpSelectData, [enIndex, 'value'], null);
	} else if (jpIndex !== -1) {
		return get(enSelectData, [jpIndex, 'value'], null);
	}

	return null;
};

export const SearchBarJP: FC<SearchPropsJP> = ({
	selectOptions,
	placeholder,
	onSubmit,
	autoCompleteDefaultOptions,
	autoCompleteOptions,
	autoCompleteOnchange,
	isClearSearchVal,
	clearValCallback,
	setAutoVal,
	autoVal,
}) => {
	const dispatch = useDispatch();
	const lang = useSelector<any>((state) => state?.user?.lang) as any;
	const searchType: any = useSelector<any>(
		(item) => item?.searchJp?.searchType
	);
	const [defaultSelectOptions, setDefaultSelectOptions] = useState<any>([]);
	const [searchParams] = useSearchParams();
	const searchVal = searchParams.get('searchVal');

	useEffect(() => {
		if (lang == 'en') {
			setDefaultSelectOptions(enSelectData);
		} else {
			setDefaultSelectOptions(jpSelectData);
		}
		const value = findMatchingValue(searchType);
		dispatch(setSearchType(value));
	}, [lang]);

	useEffect(() => {
		dispatch(setSearchType('all'));
	}, []);

	useEffect(() => {
		if (!searchVal || size(searchVal) <= 0) return;
		setAutoVal(trim(searchVal));
	}, [searchVal]);

	const onSelectChange = useCallback<
		Exclude<SelectProps['onChange'], undefined>
	>((e) => {
		dispatch(setSearchType(e));
	}, []);

	let modalRef: any;
	const onClick = useCallback(() => {
		let splitVals: any[] = [];
		if (autoVal && size(autoVal) > 0) {
			splitVals = autoVal?.trim()?.split(/[\u0020\u3000]/g);
			if (size(splitVals) > 3) {
				modalRef = Modal.confirm({
					title: t('UserAdmin.Warning'),
					icon: <ExclamationCircleFilled />,
					content: (
						<span>
							<p>{t('CommonJp.maximumKeywords')}</p>
							<p>{t('CommonJp.maximumKeywordsPls')}</p>
						</span>
					),
					footer: [
						<div
							key='keywords'
							className='flex flex-row justify-end items-center'
						>
							<Button
								key='submit'
								type='primary'
								danger
								onClick={() => {
									modalRef.destroy();
									const keywordStr = splitVals?.slice(0, 3)?.join(' ');
									onSubmit && onSubmit(keywordStr, searchType);
								}}
							>
								{t('CommonJp.ok')}
							</Button>
						</div>,
					],
				});
			} else {
				const keywordStr = splitVals?.join(' ');
				onSubmit && onSubmit(keywordStr, searchType);
				isClearSearchVal = true;
			}
		} else {
			const keywordStr = autoVal;
			onSubmit && onSubmit(keywordStr, searchType);
			isClearSearchVal = true;
		}
	}, [searchType, autoVal, isClearSearchVal]);

	useEffect(() => {
		if (!selectOptions || size(selectOptions) <= 0) return;

		dispatch(setSearchType(selectOptions[0]?.value));
	}, [selectOptions]);

	const autoOnChange = useCallback<
		Exclude<AutoCompleteProps['onChange'], undefined>
	>((e, options) => {
		setAutoVal(e);
		autoCompleteOnchange && autoCompleteOnchange(e, options);
	}, []);

	const onFocus = useCallback<
		Exclude<AutoCompleteProps['onFocus'], undefined>
	>(() => {
		if (!isClearSearchVal) return;

		clearValCallback && clearValCallback();

		setAutoVal(null);
		autoCompleteOnchange &&
			autoCompleteOnchange(null, {
				label: '',
			});
	}, [isClearSearchVal, clearValCallback]);
	return (
		<div className='flex justify-between'>
			<StyleSelect
				size='large'
				options={selectOptions || defaultSelectOptions}
				value={searchType || defaultSelectOptions[0]?.value}
				onChange={onSelectChange}
				className='text-center hover:border-[#ccc]'
			/>
			<div className='flex-1'>
				<AutoComplete
					options={autoCompleteOptions || autoCompleteDefaultOptions}
					className='w-full hover:border-[#ccc]'
					onChange={autoOnChange}
					onFocus={onFocus}
					value={autoVal}
					filterOption={false}
				>
					<Input
						size='large'
						className='hover:border-[#ccc]'
						onPressEnter={onClick}
						placeholder={placeholder || t('LoginJp.SearchPlaceholder')}
						value={autoVal}
						allowClear
					/>
				</AutoComplete>
			</div>
			<Button type='primary' size='large' onClick={onClick} className='w-36'>
				{t('HomeJp.Search')}
			</Button>
		</div>
	);
};
