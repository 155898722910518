import { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

import {
	VerticalMenuLayout,
	LazyLoadHome,
	LazyLoadShoppingCart,
	LazyLoadProductSearch,
	LazyLoadCheckout,
	LazyLoadSuccessfully,
	LazyLoadOrderHistory,
	LazyLoadExceptional,
	LazyLoadOrderDetail,
	LazyLoadProfileAccount,
	LazyLoadSuccessLogin,
	LazyLoadLogin,
	LazyLoadFavorite,
} from '../pages';

// Auth guard for the routes that requires permissions
export const RequireAuth = ({ children }: { children: JSX.Element }) => {
	const auth = useContext(AuthContext);
	const location = useLocation();
	if (!auth.isAuthenticated()) {
		return <Navigate to='/' state={{ from: location }} replace />;
	}

	return children;
};

// The routes sharing the same layout -- Applayout.
// Feel free to create different layouts for different scenarioes.
export const ApplayoutRoutes = [
	{
		path: '/',
		element: <LazyLoadHome />,
	},
	{
		path: '/shopping-cart',
		element: <LazyLoadShoppingCart />,
	},
	{
		path: '/product-search',
		element: <LazyLoadProductSearch />,
	},
	{
		path: '/checkout',
		element: <LazyLoadCheckout />,
	},
	{
		path: '/successfully',
		element: <LazyLoadSuccessfully />,
	},
	{
		path: '/exceptional',
		element: <LazyLoadExceptional />,
	},
	{
		path: '/my-order',
		element: <LazyLoadOrderHistory />,
	},
	{
		path: '/order-detail',
		element: <LazyLoadOrderDetail />,
	},
	{
		path: '/my-quotations',
		// element: <LazyLoadMyQuotations />,
		element: (
			<div className='w-full h-40 text-center mt-7'>
				This page is coming soon
			</div>
		),
	},
	{
		path: '/profile-account',
		element: (
			<VerticalMenuLayout>
				<LazyLoadProfileAccount />
			</VerticalMenuLayout>
		),
	},
	{
		path: '/profile-account',
		element: (
			<VerticalMenuLayout>
				<LazyLoadProfileAccount />
			</VerticalMenuLayout>
		),
	},
	{
		path: '/my-favorite',
		element: <LazyLoadFavorite />,
	},
];

export const PublicRoutes = [
	{
		path: '/login/callback',
		element: <LazyLoadSuccessLogin />,
	},
	{
		path: '/login-page',
		element: <LazyLoadLogin />,
	},
];
