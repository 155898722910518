import * as apj from './routes';
import * as cn from './routesCN';
import * as jp from './routesJP';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { myLoginService } from '@/services/login.service';

const getLocalRegion = () => {
	const region = useSelector<any>((state) => state?.user?.region) as any;
	const [regionData, setCountry] = useState<string | any>('');

	if (typeof window !== 'undefined') {
		const lang = useSelector<any>((state) => state?.user?.lang) as any;
		const CK_ISO_CODE = 'jp';
		const CK_LANG_CODE = lang === 'en' ? 'en' : 'ja';
		document.cookie = `CK_ISO_CODE=${CK_ISO_CODE};domain=.thermofisher.com;path=/;`;
		document.cookie = `CK_LANG_CODE=${CK_LANG_CODE};domain=.thermofisher.com;path=/;`;
		document.cookie = `token=${myLoginService.getTokenData()}`;
	}

	useEffect(() => {
		setCountry(region);
		getRouter();
	}, [regionData]);

	const getRouter = () => {
		switch (regionData) {
			case 'CN':
				return cn;
			case 'EN':
				return apj;
			case 'JP':
				return jp;
			default:
				return jp;
		}
	};
	return getRouter();
};

export default getLocalRegion;
