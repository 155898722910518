import React from 'react';
import styled from '@emotion/styled';
import { Outlet } from 'react-router-dom';
import { Header, Footer } from '@/containers/JP';

const AppContainer = styled('div')`
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	overflow-x: hidden;
`;

const AppLayoutJP = () => {
	return (
		<AppContainer className='w-full'>
			<Header />
			<div className='flex flex-1 flex-col w-full'>
				<Outlet />
			</div>
			<Footer />
		</AppContainer>
	);
};

export default AppLayoutJP;
