import { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

import {
	LazyLoadHome,
	LazyLoadSuccessLogin,
	LazyLoadLogin,
	LazyLoadShoppingCart,
} from '../pages';

// Auth guard for the routes that requires permissions
export const RequireAuth = ({ children }: { children: JSX.Element }) => {
	const auth = useContext(AuthContext);
	const location = useLocation();
	if (!auth.isAuthenticated()) {
		return <Navigate to='/' state={{ from: location }} replace />;
	}

	return children;
};

export const ApplayoutRoutes = [
	{
		path: '/',
		element: <LazyLoadHome />,
	},
	{
		path: '/my-order',
		element: (
			<div className='w-full h-40 text-center mt-7'>
				This page is coming soon :CN
			</div>
		),
	},
];

export const PublicRoutes = [
	{
		path: '/login/callback',
		element: <LazyLoadSuccessLogin />,
	},
	{
		path: '/login-page',
		element: <LazyLoadLogin />,
	},
	{
		path: '/shopping-cart',
		element: <LazyLoadShoppingCart />,
	},
];
